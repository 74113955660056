<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Listing from "@/components/listing";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { initFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
import { getFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
import moment from "moment";
export default {
  page: {
    title: `Orders details  -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Listing,
  },
  props: ["id"],
  data() {
    return {
      title: "Order Details",
      exportFileName: "order_details.csv",
      items: [
        {
          text: "Orders",
        },
        {
          text: "Details",
          active: true,
        },
      ],
      fields: [
        {
          key: "item_type",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "source",
          sortable: true,
        },
        {
          key: "price",
          sortable: true,
        },
        {
          key: "short_desc",
          sortable: true,
        },
          {
          key: "start_date",
          sortable: true,
        },
        {
          key: "end_date",
          sortable: false,
        },
        {
          key: "period",
          sortable: false,
        },
        {
          key: "course_bundle_list",
          sortable: false,
        },
      ],
      listData: [],
      exportFieldName: [],
    };
  },
  methods: {
    async getOrderDetails() {
      try {
        const data = await getFirebaseDataBackend().getDocData("orders", this.id);
        if(data) {
          if(data.order_details){
            for(const row of data.order_details){
              row.start_date = (row.start_date) ? moment.unix(row.start_date).format("DD/MM/YYYY") : '';
              row.end_date = (row.end_date) ? moment.unix(row.end_date).format("DD/MM/YYYY") : '';
              row.period = (row.period) ? row.period : '';
              this.listData.push({order_id: this.id, ...row})
            }
          }
        }
      } catch(error) {
        console.log(error.message)
        Swal.fire("Error!", "Unable to retrieve Data", "error");
      }
    },
    generateExportFields() {
      this.exportFieldName = ["order_id", "item_id"];
      for (const row of this.fields) {
        if( row.key !== "action") {
          this.exportFieldName.push(row.key);
        }
      }
    },
    back() {
      if(this.id) {
        this.$router.go(-1);
      }
    }
  },
  mounted() {
    this.title = `Order (${this.id}) - ${this.title}`;
    this.exportFileName = `order_details_${this.id}.csv`;
    initFirebaseDataBackend();
    this.getOrderDetails();
    this.generateExportFields();
  },
};
</script>

<template>
  <Layout>
    <div v-if="this.id">
      <a href="#" @click="back">&#60;&#60;Back</a>
      <br/><br/>
    </div>
    <PageHeader :title="title" :items="items" />
    <div class="row mt-3">
      <div class="col-8">
         <download-csv
            :data="listData" :fields="exportFieldName" :name="exportFileName">
            <b-button variant="primary">Download Order details CSV</b-button>
        </download-csv>
      </div>
    </div>
    <Listing :fields="fields" :listData="listData" :sortBy="'id'" />
  </Layout>
</template>
