// import VueFormGenerator from 'vue-form-generator';
import moment from 'moment';
// import { initFirebaseDataBackend } from '../helpers/firebase/dataUtils';
// import { getFirebaseDataBackend } from '../helpers/firebase/dataUtils';


export const getSchema = (inclusiveListCount) => {
  return {
    groups: [
      {
        legend: 'Basic Information',
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Subscription Name',
            model: 'name',
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Short Description',
            model: 'short_desc',
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'PaymentGateway Item Description',
            model: 'payment_desc',
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Long Description',
            model: 'long_desc',
            required: false,
          },
          {
            type: 'textArea',
            rows: inclusiveListCount,
            label: 'Inclusive Items',
            model: 'inclusive_list',
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Price',
            model: 'price',
            min: 1,
            step: "0.01",
            required: true,
            validator: ['required']
          },
          {
            type: "switch",
            label: "Enable Fixed Expiry",
            model: "enable_fixed_expiry",
            multi: true,
            readonly: false,
            featured: false,
            disabled: false,
            default: false,
            textOn: "Period",
            textOff: "Fixed Date",
            onChanged (model, newVal) {
              if (!newVal || newVal === 0) {
                model.fixed_expiry_date = 0;
              // } else {
              //   model.period_month = 0;
              }
              return model;
            }
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Period (Months)',
            model: 'period_month',
            min: 1,
            required: true,
            validator: ['required'],
          },
          {
            type: "input",
            inputType: "date",
            label: "Fixed Expiry Date",
            placeholder: "Fixed Expiry Date",
            model: "fixed_expiry_date",
            required: true,
            default: moment().add(1, "y").endOf("Year").unix()*1000,
            validator: ['required','date'],
            set: function(model, val) {
                model.fixed_expiry_date = new Date(val).valueOf();
            },
            visible(model) {
              return model && model.enable_fixed_expiry;
            },
          },
          {
            type: "switch",
            label: "Featured",
            model: "featured_flag",
            multi: true,
            readonly: false,
            featured: false,
            disabled: false,
            default: true,
            textOn: "Featured",
            textOff: "Non-Featured",
          },
          {
            type: 'select',
            label: 'Type',
            model: 'type',
            values: function () {
              return [
                {
                  id:"VIP1", name: "VIP1"
                }
              ];
            },
            default:"VIP1",
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Savings (RM)',
            model: 'saving_amount',
            min: 0,
            step: "0.01",
            required: false,
          },
          {
            type: "switch",
            label: "Status",
            model: "status",
            multi: true,
            readonly: false,
            featured: false,
            disabled: false,
            default: true,
            textOn: "Active",
            textOff: "Inactive",
            valueOn: "active",
            valueOff: "inactive"
          },
          
        ]
      },
      
    ]
  }
}
