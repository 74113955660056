<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Listing from "@/components/listing";
import appConfig from "@/app.config";
import moment from "moment";
// import Swal from "sweetalert2";
import { initFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import { getFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
// import { getAuth } from 'firebase/auth';

export default {
  page: {
    title: `Payment Details  -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Listing,
  },
  props: ["id"],
  data() {
    return {
      title: "Payment Details",
      listData: [],
      fields: [
        {
          key: "transaction_id",
          label: "3rd Party Payment ID",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "payment_date",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "order_id",
          label: "Reference No",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "phone",
          label: "Mobile No",
          sortable: false,
        },
        {
          key: "amount_paid",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "payment_status",
          sortable: true,
        },
        {
          key: "payment_failed_remark",
          label: "Error Remark",
          sortable: false,
        },
        {
          key: "transaction_type",
          sortable: true,
          tdClass: "c-sm",
        },
      ],
      schema: null,
      isSaving: false,
      isSpinner: false,
      formOptions: {
        validateAfterChanged: true,
        validateAfterLoad: false,
      },
      exportFieldName: [],
      items: [
        {
          text: "Payment",
        },
        {
          text: "List",
          active: true,
        },
      ],
    };
  },
  methods: {
    async getPayments() {
      try {
        this.isSpinner = true;
        let resultData = null;
        const queryParam = [
          {
            param_type: "where",
            field_name: "payment_date",
            operator: ">",
            value: 0,
          },
        ];
        if (this.id) {
          resultData = await getFirebaseDataBackend().getListData(
            `orders/${this.id}/payment`,
            queryParam
          );
        } else {
          resultData = await getFirebaseDataBackend().getCollectionGroupData(
            "payment",
            queryParam
          );
        }
        if (resultData) {
          resultData.map((data) => {
            if (data.payment_date) {
              data.payment_date = moment
                .unix(data.payment_date)
                .format("DD/MM/YYYY HH:MM:SS");
            }
          });
          this.listData = resultData;
        }
        this.isSpinner = false;
      } catch (error) {
        console.log(error);
      }
    },
    generateExportFields() {
      this.exportFieldName = ["id"];
      for (const row of this.fields) {
        if (row.key !== "action") {
          this.exportFieldName.push(row.key);
        }
      }
    },
    back() {
      if (this.id) {
        this.$router.go(-1);
      }
    },
  },
  created() {
    initFirebaseDataBackend();
  },
  mounted() {
    this.getPayments();
    this.generateExportFields();
  },
};
</script>

<template>
  <Layout>
    <div v-if="this.id">
      <a href="#" @click="back">&#60;&#60;Back</a>
      <br /><br />
    </div>
    <PageHeader :title="title" :items="items" />
    <div class="d-inline-flex my-3">
      <download-csv
        :data="listData"
        :fields="exportFieldName"
        :name="'payment_list.csv'"
      >
        <b-button variant="primary">Download Payment CSV</b-button>
      </download-csv>
    </div>

    <!-- <div
      v-if="isSpinner"
      style="text-align: center; background-color: white; min-height: 200px"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div> -->
    <Listing :fields="fields" :listData="listData" :sortBy="'id'" />
  </Layout>
</template>
