<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Courses",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  props: ["id", "pageTitle"],
  data() {
    return {
      title: "Courses",
      items: [
        {
          text: "Course",          
        },
        {
          text: "New Courses",
          active: true,
        },
      ],
      tabIndex: 0,
    };
  },
  computed: {},

  methods: {
    tabChange(tabIndex) {
      if (this.$route.name.includes("edit")) {
        // Update Mode
        if (tabIndex === 0 && this.$route.name != "course-detail-edit") {
          this.$router.push({
            name: "course-detail-edit",
          });
        }
        if (tabIndex === 1 && this.$route.name != "course-material-edit") {
          this.$router.push({
            name: "course-material-edit",
          });
        }
      } else {
        // Add New Mode
        if (tabIndex === 0 && this.$route.name != "course-detail") {
          this.$router.push({
            name: "course-detail",
          });
        }
        if (tabIndex === 1 && this.$route.name != "course-material") {
          this.$router.push({
            name: "course-material",
          });
        }
      }
    },
  },
  watch: {
    $route() {
      if (
        this.$route.name === "course-material" ||
        this.$route.name === "course-material-edit"
      ) {
        this.tabIndex = 1;
      } else {
        this.tabIndex = 0;
      }
    },
  },

  created() {
    if (
      this.$route.name === "course-material" ||
      this.$route.name === "course-material-edit"
    ) {
      this.tabIndex = 1;
    } else {
      this.tabIndex = 0;
    }
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div>
      <!-- Tab Header -->
      <div class="row no-gutters">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11 col-xxl-11">
          <b-tabs
            nav-class="nav-tabs-custom"
            content-class="py-2"
            v-model="tabIndex"
            @input="tabChange"
          >
            <b-tab title="Course Detail" title-item-class="py-1 bg-white">
            </b-tab>

            <b-tab title="Course Material" title-item-class="py-1 bg-white">
            </b-tab>
          </b-tabs>
          
            <router-view :key="$route.fullPath"></router-view>
          
        </div>
      </div>
      <!-- Tab Content -->
    </div>
  </Layout>
</template>