<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      appName: process.env.VUE_APP_NAME,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.tryingToLogIn = true;
        // Reset the authError if it existed.
        this.authError = null;

        return this.$store
          .dispatch("auth/logIn", {
            email: this.email,
            password: this.password,
          }) // eslint-disable-next-line no-unused-vars
          .then((response) => {
            if(process.env.VUE_APP_DEFAULT_AUTH === 'backend'){
              if (response.status !== 200) {
                this.tryingToLogIn = false;
                this.isAuthError = true;
                this.authError = response.data
                  ? response.data.reason
                  : "Invalid Credential";
              } else {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                this.$router.push(
                  this.$route.query.redirectFrom || {
                    path: "/",
                  }
                );
              }
            }  else if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
              this.tryingToLogIn = true;
              // Reset the authError if it existed.
              this.authError = null;
              return (
                this.$store
                  .dispatch("auth/logIn", {
                    email: this.email,
                    password: this.password,
                  })
                  // eslint-disable-next-line no-unused-vars
                  .then((token) => {
                    this.tryingToLogIn = false;
                    this.isAuthError = false;
                    // Redirect to the originally requested page, or to the home page
                    this.$router.push(
                      this.$route.query.redirectFrom || {
                        path: "/",
                      }
                    );
                  })
                  .catch((error) => {
                    this.tryingToLogIn = false;
                    this.authError = error ? error : "";
                    this.isAuthError = true;
                  })
              );
            } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
              const { email, password } = this;
              if (email && password) {
                this.$store.dispatch("authfack/login", {
                  email,
                  password,
                });
              }
            }
          })
          .catch((error) => {
            this.tryingToLogIn = false;
            this.authError = error ? error : "Invalid Dispatch";
            this.isAuthError = true;
          });

        // -------------------------------------------------------------------------------
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo-with-word.png"
                  alt
                  height="65"
                  class="logo logo-dark"
                />
                <img
                  src="@/assets/images/logo-with-word.png"
                  alt
                  height="65"
                  class="logo logo-light"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p class="text-muted">Sign in to {{appName}}.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div
                    v-if="notification.message"
                    :class="'alert ' + notification.type"
                  >
                    {{ notification.message }}
                  </div>

                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Email"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="email"
                        type="text"
                        placeholder="Enter email"
                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.email.required"
                          >Email is required.</span
                        >
                        <span v-if="!$v.email.email"
                          >Please enter valid email.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <div class="float-end">
                        <router-link
                          to="/forgot-password"
                          class="text-muted"
                          >Forgot password?</router-link
                        >
                      </div>
                      <label for="input-2">Password</label>
                      <b-form-input
                        id="input-2"
                        v-model="password"
                        type="password"
                        placeholder="Enter password"
                        :class="{
                          'is-invalid': submitted && $v.password.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && !$v.password.required"
                        class="invalid-feedback"
                      >
                        Password is required.
                      </div>
                    </b-form-group>
                    <!--
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="auth-remember-check"
                      />
                      <label class="form-check-label" for="auth-remember-check"
                        >Remember me</label
                      >
                    </div>
                    -->
                    <div class="mt-3 text-end">
                      <b-button type="submit" variant="primary" class="w-sm" :disabled="tryingToLogIn">
                        Log In
                        <template v-slots:default>
                          <b-spinner small v-if="tryingToLogIn"></b-spinner>
                        </template>
                      </b-button>
                    </div>
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <p>
                © {{ new Date().getFullYear() }} {{appName}}. All Rights Reserved
              </p>
            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>
<style lang="scss" module></style>
