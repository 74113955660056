<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import * as broadcastFormSchema from '../../../forms/broadcastFormSchema';
import moment from 'moment';
import Swal from "sweetalert2";
import {nanoid} from "nanoid";
import { initFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
import { getFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";
// import { getAuth } from 'firebase/auth';

export default {
  page: {
    title: `Broadcast Details -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,

  },
  props: ["id","pageTitle"],
  data() {
    return {
      title: "Add New Brodcast",
      model: { 
        broadcast_method: [],
        broadcast_filter: [],
        broadcast_sms_template: [],
        broadcast_email_template: [],
        broadcast_template: "",
        broadcast_recipeint_count: 0,
        broadcast_recipeint_list: [],
      },
      recipeint_count: 'Total Recipient : 0',
      schema: null,
      isSaving: false,
      smsTemplate: [],
      emailTemplate: [],
      formOptions: {
        validateAfterChanged: true,
        validateAfterLoad: false,
      },
      courseData: null,
      exportFieldName: [],
      days_to_expiry: 8,
    };
  },
  computed : {
    selectedFilter(){
        return this.model.broadcast_filter;
      },
  },
  watch: {
    // whenever question changes, this function will run
    selectedFilter () {
      if(this.model.broadcast_filter && this.model.broadcast_filter.id) {
        getFirebaseDataBackend().getBroadcastRecipientList(this.model.broadcast_filter.id, this.days_to_expiry)
            .then(resultData => {
              this.recipeint_count = `Total Recipient: ${resultData.length}`;
              this.model.broadcast_recipeint_list = resultData;
              // console.log(this.model.broadcast_filter.id)
            })
            .catch((error) => {
                console.log(error);
            });
      }
    }
  },
  methods: {
    initialModelState() {
      this.smsTemplate = [];
      this.emailTemplate = [];
      this.model = { 
          broadcast_method: [],
          broadcast_filter: [],
          broadcast_sms_template: [],
          broadcast_email_template: [],
          broadcast_template: "",
          broadcast_subject: "",
          broadcast_recipeint_count: "",
          broadcast_recipeint_list: []
      };
    },
    getRecipientList() {
      return this.model.broadcast_recipeint_list;
    },
    generateExportFields() {
      this.exportFieldName.push("id");
      this.exportFieldName.push("first_name");
      this.exportFieldName.push("last_name");
      this.exportFieldName.push("email");
      this.exportFieldName.push("mobile_no");
    },
    async submitBroadcastDetails() {
      try{
        let newTemplateData = null;
        // Add temporary template to collection if others is selected.
        if (this.model.broadcast_method.id === "sms") {
          if(this.model.broadcast_sms_template.id === "others"){
            newTemplateData = await getFirebaseDataBackend()
              .addData("sms_template", {text: this.model.broadcast_template, type: "temporary"});
          } 
        } else if (this.model.broadcast_method.id === "email") {
          if (this.model.broadcast_email_template.id === "others") {
            newTemplateData = await getFirebaseDataBackend()
              .addData("email_template", {html: this.model.broadcast_template, subject: this.model.broadcast_subject, type: "temporary"});
          }
        }
        const templateId = (newTemplateData && newTemplateData.id) ? newTemplateData.id : this.model.broadcast_sms_template.id;
        const batchId = nanoid(20);
        let counter = 0, tempRecipientList = [], errorFlag = false;
        this.model.broadcast_recipeint_list.forEach((row, index) => {
          // Only populate default data if is method = email

          if (this.model.broadcast_method.id === "email") {
            tempRecipientList.push({
              email: row.email,
              data: {
                first_name: row.first_name ?? "",
                last_name: row.last_name ?? "",
                email: row.email ?? "",
                mobile_no: row.mobile_no ?? "",
                expiry_date: (row.subscription_expiry_date) ?
                  moment.unix(row.subscription_expiry_date).format("DD/MM/YYYY") : "-",
              }
            });
          } else if (this.model.broadcast_method.id === "sms") {
            tempRecipientList.push({
              mobile_no: row.mobile_no,
              data: {
              }
            });
          } 
        
          // send sms for every 50 records, send email for every 100 records
          if ((counter >= 99 && this.model.broadcast_method.id === "email") ||
            (counter >= 49 && this.model.broadcast_method.id  === "sms") ||
            index === (this.model.broadcast_recipeint_list.length-1)) {
            const broadcastParam = {
              broadcast_type: this.model.broadcast_method.id,
              broadcast_template_id: templateId,
              recipient_data: tempRecipientList,
              source: "admin portal",
              batch_id: batchId,
            };

            // Call backend function
            getFirebaseDataBackend().sendBroadcast(broadcastParam)
              .then(() =>{
              })
              .catch(error => {
                console.log(error);
                errorFlag = true;
              })
            tempRecipientList = [];
            counter=0;
          }
          counter++;
        });

        if (!errorFlag) {
          Swal.fire({
              icon: "success",
              title: "Created!",
              html: `Broadcast Notification<br>Batch ${batchId}`,
          });
        } else {
          Swal.fire({
              icon: "warning",
              title: "Partial Failed!",
              html: `Broadcast Notification<br>Batch ${batchId}`,
          });
        }
        
        this.$router.push({name:"broadcast-list-email"});
      } catch (error) {
        console.log(error)
        Swal.fire("Failed!", "Error in creating broadcast notification.", "error");
      }
    },
  },
  created() {
    initFirebaseDataBackend();
  },
  mounted() {
    const remoteConfig = getRemoteConfig();
    // remoteConfig.settings.minimumFetchIntervalMillis = process.env.VUE_APP_REMOTE_CONFIG_REFRESH_TIME;

    fetchAndActivate(remoteConfig)
    .then(() => {
      const val = getValue(remoteConfig, "days_to_expiry");
      this.days_to_expiry = val._value;
    })
    .catch((err) => {
       console.log(err);
    });
   
    this.initialModelState();
    this.generateExportFields();
    const queryParam = [{
      param_type: "where",
      field_name: "text",
      operator: "!=",
      value: ""
    }];
    getFirebaseDataBackend().getListData("sms_template", queryParam)
        .then(smsData => {
          this.smsTemplate = smsData.filter(row => ! row.type || (row.type !== 'temporary' && row.type !== 'system'));
          const emailQueryParam = [{
            param_type: "where",
            field_name: "html",
            operator: "!=",
            value: ""
          }];
          getFirebaseDataBackend().getListData("email_template", emailQueryParam)
              .then(emailData => {
                this.emailTemplate = emailData.filter(row => ! row.type || (row.type !== 'temporary' && row.type !== 'system'));
                this.schema = broadcastFormSchema.getSchema(this.smsTemplate, this.emailTemplate);
              });
        })
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">User Course Detail</h4>
            <div class="card ">
              <form action="" @submit.prevent="submitBroadcastDetails">
                  <vue-form-generator tag="div" :schema="schema" :options="formOptions" :model="model" />
                  <a href="https://html-online.com/editor/" target="_blank">Click here to preview HTML page</a>
                  <br/><br/>
                  <p>{{recipeint_count}}</p>
                  <download-csv
                    :data="model.broadcast_recipeint_list" :fields="exportFieldName" :name="'recipient_list.csv'">
                    <b-button variant="primary">Download Recipient List CSV</b-button>
                </download-csv>
                  <div class="d-flex justify-content-end mt-3 pr-4">
                      <button type="submit" class="btn btn-primary btn-lg">
                          {{ isSaving ? 'Saving...' : 'Submit'}}
                      </button>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>