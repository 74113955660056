<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Listing from "@/components/listing";
import appConfig from "@/app.config";
import { initFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import { getFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  page: {
    title: `${process.env.VUE_APP_SHORT_NAME}- Users Subscription`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Listing,
  },
  data() {
    return {
      title: "Users Subscription",
      items: [
        {
          text: "Users",
        },
        {
          text: "User Subscription",
          active: true,
        },
      ],
      fields: [
        {
          key: "action",
          tdClass: "c-action",
        },
        {
          key: "user_email",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "user_mobile_no",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "subscription_name",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "price",
          label: "Price (RM)",
          sortable: true,
          thClass: "c-currency",
          tdClass: "c-sm c-currency",
          formatter: (value) => {
            return parseFloat(value).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "type",
          sortable: true,
        },
        {
          key: "expiry_date",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "period",
          sortable: true,
        },
        {
          key: "order_id",
          sortable: true,
        },
        {
          key: "order_date",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "payment_date",
          sortable: true,
          tdClass: "c-md",
        },

        {
          key: "payment_reference",
          sortable: true,
          tdClass: "c-md",
        },
      ],
      listData: [],
      exportFieldName: [],
      sortDesc: true,
      statusFilter: "",
      options: [
          { text: '', value: '' },
          { text: 'In-used', value: 'in-used'},
          { text: 'Cancelled', value: 'cancelled' },
      ],
    };
  },
  methods: {
    async getUserSubscriptionList() {
      try {
        const queryParam = [
          {
            param_type: "where",
            field_name: "created_at",
            operator: ">",
            value: 0,
          },
          {
            param_type: "orderBy",
            field_name: "created_at",
            operator: "desc",
          },
        ];
        const resultData = await getFirebaseDataBackend().getListData(
          "user_subscription",
          queryParam
        );
        if (resultData) {
          resultData.map((data) => {
            if (!data.user_first_name) {
              data.user_first_name = "";
            }
            if (!data.user_last_name) {
              data.user_last_name = "";
            }
            if (!data.user_email) {
              data.user_email = "";
            }
            if (!data.user_mobile_no) {
              data.user_mobile_no = "";
            }

            if (data.order_date) {
              data.original_order_date = data.order_date;
              data.order_date = moment
                .unix(data.order_date)
                .format("DD/MM/YYYY");
            }
            if (data.payment_date) {
              data.payment_date = moment
                .unix(data.payment_date)
                .format("DD/MM/YYYY");
            }
            if (data.expiry_date) {
              data.expiry_date = moment
                .unix(data.expiry_date)
                .format("DD/MM/YYYY");
            }
            if (!data.type || data.type === "") {
              data.type = "purchased";
            }
            if (!data.status || data.status !== 'cancelled') {
              data.status = 'in-used';
            }
          });
          this.listData = resultData;

          if (this.statusFilter === "in-used") {
            this.listData = this.listData.filter(row => row.status !== "cancelled")
          } else if (this.statusFilter === "cancelled") {
            this.listData = this.listData.filter(row => row.status === "cancelled")
          }
        }
      } catch (error) {
        console.log(error.message);
        Swal.fire("Error!", "Unable to retrieve Data", "error");
      }
    },
    generateExportFields() {
      this.exportFieldName = [];
      for (const row of this.fields) {
        if (row.key !== "action") {
          this.exportFieldName.push(row.key);
        }
      }
    },
    addUserSubscription() {
      this.$router.push({
        name: "user-subscription-new",
        params: { pageTitle: "Add New User Subscription" },
      });
    },
    onStatusFilterChange() {
      this.getUserSubscriptionList();
    },
  },
  mounted() {
    initFirebaseDataBackend();
    this.getUserSubscriptionList();
    this.generateExportFields();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-inline-flex">
      <div class="px-3">
          <label>Status Filter:&nbsp;&nbsp;&nbsp;</label>
          <b-form-select  v-model="statusFilter" :options="options" @change="onStatusFilterChange(statusFilter)" cclass="mb-2">
          </b-form-select>
      </div>
      <div>
        <b-button variant="primary" @click="addUserSubscription">Add</b-button>
      </div>
      <div class="px-3">
        <download-csv
          :data="listData"
          :fields="exportFieldName"
          :name="'user_subscription_list.csv'"
        >
          <b-button variant="primary">Download CSV</b-button>
        </download-csv>
      </div>
    </div>
    <div class="mt-3">
      <Listing
        :fields="fields"
        :listData="listData"
        :editRoute="'user-subscription-cancellation'"
        :sortBy="'original_order_date'"
        :sortDesc="sortDesc"
      />
    </div>
  </Layout>
</template>
