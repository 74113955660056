<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import * as userCourseFormSchema from '../../../forms/userCourseFormSchema';
import moment from 'moment';
import Swal from "sweetalert2";
import { initFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
import { getFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";

// import { getAuth } from 'firebase/auth';

export default {
  page: {
    title: "User Course Details",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,

  },
  props: ["id","pageTitle"],
  data() {
    return {
      title: "Add New User Course",
      model: null,
      schema: null,
      isSaving: false,
      formOptions: {
        validateAfterChanged: true,
        validateAfterLoad: false,
      },
      courseData: null,
      myCourseUrl: ""
    };
  },
  methods: {
    initialModelState() {
      this.model = { 
            subscription: '',
            user: '',
            price: 0,
            type: 'gift',
            entry_remark: '',
            custom_message: '',
      };
    },
    async submitUserDetails() {
      try{
        // Get user info
        const user_id = this.model.user.id;
        const userData = await getFirebaseDataBackend().getDocData("users", this.model.user.id);

        // Get selected subscription
        const selectedCourseRecord = this.courseData.filter(row => row.id === this.model.course.id);
        const newEndDate = (selectedCourseRecord[0].end_date && selectedCourseRecord[0].end_date > 0) ? selectedCourseRecord[0].end_date : 0;
        const newStartDate = (selectedCourseRecord[0].start_date && selectedCourseRecord[0].start_date > 0) ? selectedCourseRecord[0].start_date : moment().unix();
        
        // Remove unused model data
        delete(this.model.course);
        delete(this.model.user);

        // Format Date
        const userCourseData = {
          course_id: selectedCourseRecord[0].id,
          user_email: userData.email,
          user_mobile_no: userData.mobile_no,
          user_first_name: userData.first_name,
          user_last_name: userData.last_name,
          user_id: user_id,
          course_start_date: newStartDate,
          course_end_date: newEndDate,
          course_name: selectedCourseRecord[0].name,
          short_desc: selectedCourseRecord[0].short_desc,
          image: selectedCourseRecord[0].image,
          price: (this.model.price > 0) ? this.model.price : selectedCourseRecord[0].price,
          status: 'active',
        }
        
        await getFirebaseDataBackend()
        .addData("user_course",  {...this.model, ...userCourseData})
        .then(() => {
          // Add a new record to mail table for email notification
          const mailData = {
            to: userCourseData.user_email,
            mail_category: "admin_free_course",
            template: {
              name: "admin_free_course_subscription",
              data: {
                item_type: "course",
                item_name: userCourseData.course_name,
                custom_message: this.model.custom_message ?? "There is a special reward for you",
                first_name: userCourseData.user_first_name ?? "",
                last_name: userCourseData.user_last_name ?? "",
                check_item_url: this.myCourseUrl,
              },
            },
          };

          getFirebaseDataBackend()
          .addData("mail", mailData)
          .then(() => {
            // Add a new document in collection "cities"
            Swal.fire("Created!", "User course Created.", "success");
            this.$router.push({
              name: "user-course-list",
            })
          })
          .catch(error => {
            console.log(error);
            Swal.fire("Failed!", "User course created but failed to trigger email.", "error");
          });
        })
        .catch(error => {
          console.log(error);
          Swal.fire("Failed!", "User course failed to create.", "error");
        }); 
      } catch (error) {
        console.log(error)
        Swal.fire("Failed!", "User course failed to create.", "error");
      }
    },
  },
  created() {
    initFirebaseDataBackend();
  },
  mounted() {
    const remoteConfig = getRemoteConfig();
    // remoteConfig.settings.minimumFetchIntervalMillis = process.env.VUE_APP_REMOTE_CONFIG_REFRESH_TIME;
    fetchAndActivate(remoteConfig)
    .then(() => {
      const val = getValue(remoteConfig, "my_courses_url");
      this.myCourseUrl = val._value;
    })
    .catch((err) => {
      console.log(err);
      this.myCourseUrl = "https://superbullklse.com/lms/my-courses";
      getFirebaseDataBackend()
        .addData("error_log", {source: "admin_remote_courses_url_error", error_message: err})
        .catch((err) => { console.log(err); });
    });

    this.initialModelState();
    const queryParam = [{
      param_type: "where",
      field_name: "status",
      operator: "==",
      value: "active"
    }];
    const returnCourseList = [];
    const returnUserList = [];
    getFirebaseDataBackend().getListData("courses", queryParam)
        .then(data => {
          for(const row of data){
            returnCourseList.push({ id: row.id, name: row.name, short_desc: row.short_desc, image: row.image});
          }
          this.courseData = data;
          this.initialModelState();
          const queryParam = [{
            param_type: "where",
            field_name: "status",
            operator: "==",
            value: "active"
          }];
          getFirebaseDataBackend().getListData("users", queryParam)
              .then(data => {
                for(const row of data){
                  returnUserList.push({ id: row.id, name: `${row.first_name} ${row.last_name} - ${row.email}`});
                }
                this.schema = userCourseFormSchema.getSchema(returnCourseList, returnUserList);
              });
        })
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">User Course Detail</h4>
            <div class="card ">
              <form action="" @submit.prevent="submitUserDetails">
                  <vue-form-generator tag="div" :schema="schema" :options="formOptions" :model="model" />
                  <div class="d-flex justify-content-end mt-3 pr-4">
                      <button type="submit" class="btn btn-primary btn-lg">
                          {{ isSaving ? 'Saving...' : 'Submit'}}
                      </button>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>