<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Listing from "@/components/listing";
import appConfig from "@/app.config";
import { initFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import { getFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  page: {
    title: `Orders List -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Listing,
  },
  data() {
    return {
      title: "Order List",
      items: [
        {
          text: "Orders",
        },
        {
          text: "List",
          active: true,
        },
      ],
      fields: [
        {
          key: "secondAction",
          label: "Action",
          sortable: false,
          tdClass: "c-action",
        },
        {
          key: "id",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "order_date",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "item_name",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "item_type",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "order_total",          
          label: "Order Total (RM)",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
          tdClass: "c-md c-currency",
          thClass: "c-currency",
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "user_email",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "user_first_name",
          label: "First Name",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "user_last_name",
          label: "Last Name",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "geo_location",
          label: "Purchased Location",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "referred_by",
          sortable: true,
          label: "Referred By Code",
          tdClass: "c-md",
        },
      ],
      listData: [],
      listDetailData: [],
      exportHeaderFieldName: [],
      viewOption: {
        viewRoute: "order-detail",
      },
      secondActionOption: {
        route: "order-payment",
        key: "id",
        iconClass: "fa fa-credit-card",
      },
      exportDetailsFieldName: [
        "order_id",
        "item_id",
        "item_type",
        "name",
        "source",
        "price",
        "short_desc",
        "start_date",
        "end_date",
        "period",
        "course_type",
        "course_bundle_list",
      ],
      options: [
          { text: '', value: '' },
          { text: 'Paid', value: 'paid' },
          { text: 'Processing', value: 'processing' },
      ],
      orderStatusfilter: "",
      isSpinner: false,
      sortDesc: true,
    };
  },
  methods: {
    async getOrderList(filter) {
      try {
        this.isSpinner = true;
        let queryParam = [
          {
            param_type: "where",
            field_name: "order_date",
            operator: ">",
            value: 0,
          },
        ];

        if (filter && filter !== "" && filter !== "all") {
          queryParam.push({
            param_type: "where",
            field_name: "status",
            operator: "==",
            value: filter,
          })
        }

        const resultData = await getFirebaseDataBackend().getListData(
          "orders",
          queryParam
        );
        if (resultData) {
          resultData.map((data) => {
            data.referred_by  = data.referred_by ?? "";
            data.referred_by_user_id  = data.referred_by_user_id ?? "";
            data.original_order_date = Number(data.order_date) ?? 0;
            data.viewDetailField = data.order_details
                ? data.order_details.length
                : 0;
            data.item_count = data.viewDetailField ?? 0;
            data.order_date = moment
                .unix(data.order_date)
                .format("DD/MM/YYYY hh:mm:ss") ?? "";
            // Set default value
            data.item_name = "-";
            data.item_type = "-";

            if (data.order_details && data.order_details.length > 0) {
              data.item_name = (data.order_details[0].name) ? data.order_details[0].name : "-";
              data.item_type = data.order_details[0].item_type  ?? "-";
              // for (const row of data.order_details) {
              //   row.start_date = row.start_date
              //     ? moment.unix(row.start_date).format("DD/MM/YYYY hh:mm:ss")
              //     : "";
              //   row.end_date = row.end_date
              //     ? moment.unix(row.end_date).format("DD/MM/YYYY hh:mm:ss")
              //     : "";
              //   row.period = row.period ? row.period : "";
              //   this.listDetailData.push({ order_id: data.id, ...row });
              // }
            }

            // remove array order details to prevent search in listing to hang
            delete data['order_details']; 
          });
          this.listData = JSON.parse(JSON.stringify(resultData));
        }
        this.isSpinner = false;
      } catch (error) {
        console.log(error.message);
        Swal.fire("Error!", "Unable to retrieve Data", "error");
      }
    },
    generateExportHeaderFields() {
      this.exportHeaderFieldName = ["id"];
      for (const row of this.fields) {
        if (row.key !== "action") {
          if (row.key === "viewDetailField") {
            this.exportHeaderFieldName.push("item_count");
          } else {
            this.exportHeaderFieldName.push(row.key);
          }
        }
      }
      this.exportHeaderFieldName.push("referred_by_user_id");
    },
    onOrderFilterChange () {
      this.getOrderList(this.orderStatusfilter);
    }
  },
  mounted() {
    initFirebaseDataBackend();
    this.getOrderList();
    this.generateExportHeaderFields();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-inline-flex">
      <div class="px-3">
          <label>Order Status:&nbsp;&nbsp;&nbsp;</label>
          <b-form-select  v-model="orderStatusfilter" :options="options" @change="onOrderFilterChange(orderStatusfilter)" cclass="mb-2">
          </b-form-select>
      </div>
      <div>
        <download-csv
          :data="listData"
          :fields="exportHeaderFieldName"
          :name="'order_list.csv'"
        >
          <b-button variant="primary">Download CSV</b-button>
        </download-csv>
      </div>
    </div>
    <div class="mt-3">
      <!-- <div v-if="isSpinner" style="text-align: center;background-color: white;min-height: 200px;">
        <b-spinner label="Loading..."></b-spinner>
      </div> -->
      <Listing
        :fields="fields"
        :listData="listData"
        :viewOption="viewOption"
        :secondActionOption="secondActionOption"
        :sortBy="'original_order_date'"
        :sortDesc="sortDesc"
      />
    </div>
  </Layout>
</template>
