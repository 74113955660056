import { initializeApp } from 'firebase/app';
// Add the Firebase products that you want to use
import {
    getAuth,
    connectAuthEmulator,
    // signInWithPhoneNumber,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    createUserWithEmailAndPassword,
    onAuthStateChanged
    // RecaptchaVerifier,
    // linkWithCredential,
    // EmailAuthProvider,
} from 'firebase/auth';
import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
} from 'firebase/functions';
import { getApp } from "firebase/app";

class FirebaseAuthBackend {
    functions = null;
    adminRegisterAuth = null;

    constructor(firebaseConfig) {
        if (firebaseConfig) {
            // Initialize Firebase
            initializeApp(firebaseConfig);
            const auth = getAuth();
            onAuthStateChanged(auth, user => {
                if (user) {
                    sessionStorage.setItem("authUser", JSON.stringify(user));
                } else {
                    sessionStorage.removeItem('authUser');
                }
            });
        }
    }

    /**
     * Registers the user with given details
     */
    registerUser = (email, password) => {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            createUserWithEmailAndPassword(auth, email, password).then((userCredentials) => {
                // eslint-disable-next-line no-redeclare
                
                resolve(userCredentials.user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Registers the user with given details
     */
     adminRegisterUser = (email, password) => {
        return new Promise((resolve, reject) => {
            createUserWithEmailAndPassword(this.adminRegisterAuth, email, password).then((userCredentials) => {
                // eslint-disable-next-line no-redeclare
                
                resolve(userCredentials.user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            const checkUserRole = this._callFirebaseFunction('checkUserRole');
            checkUserRole({ email: email })
                .then(result => {
                    if (result.data.is_admin) {
                        signInWithEmailAndPassword(auth, email, password).then(() => {
                            // eslint-disable-next-line no-redeclare
                            let user = auth.currentUser;
                            resolve(user);
                        }, (error) => {
                            reject(this._handleError(error));
                        });
                    } else {
                        reject(this._handleError(result.data));
                    }
                })
                .catch(error => {
                    // const errorCode = error.code;
                    reject(this._handleError(error));
                });
        });
    }

    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            sendPasswordResetEmail(auth, email, { url: window.location.protocol + "//" + window.location.host + "/login" }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            const auth = getAuth();    
            auth.signOut().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    setLoggeedInUser = (user) => {
        sessionStorage.setItem("authUser", JSON.stringify(user));
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {

        if (!sessionStorage.getItem('authUser'))
            return null;
        return JSON.parse(sessionStorage.getItem('authUser'));
    }

    /**
     * Handle the error
     * @param {*} error 
     */
     _handleError(error) {
        let errorMessage = error;
        if(error.message){
            errorMessage = error.message;
        }
        return errorMessage;
    }

    /**
     * Handle the error
     * @param {*} error 
     */
     _callFirebaseFunction(functionName, functionRegion) {
        this.functions = (functionRegion && functionRegion !=="") ?  getFunctions(getApp(), functionRegion) : this.functions;
        return httpsCallable(this.functions, functionName);
    }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config 
 */
const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    _fireBaseBackend.functions = getFunctions(getApp(), 'asia-southeast2');

    const firebaseConfig = {
        apiKey: process.env.VUE_APP_APIKEY,
        authDomain: process.env.VUE_APP_AUTHDOMAIN,
        databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
        projectId: process.env.VUE_APP_PROJECTId,
        storageBucket: process.env.VUE_APP_STORAGEBUCKET,
        messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
        appId: process.env.VUE_APP_APPId,
        measurementId: process.env.VUE_APP_MEASUREMENTID
    };

    const registerUserApp = initializeApp(firebaseConfig, "adminRegisterNewUser");
    _fireBaseBackend.adminRegisterAuth = getAuth(registerUserApp);

    if (process.env.VUE_APP_ENVIRONMENT === "local") {
        connectAuthEmulator(_fireBaseBackend.adminRegisterAuth, "http://localhost:" + process.env.VUE_APP_AUTH_EMULATOR_PORT);

        const auth = getAuth();
        connectAuthEmulator(auth, "http://localhost:" + process.env.VUE_APP_AUTH_EMULATOR_PORT);
        connectFunctionsEmulator(_fireBaseBackend.functions, 'localhost', 5001);
    }
    return _fireBaseBackend;
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
}

export { initFirebaseBackend, getFirebaseBackend };