import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
} from 'firebase/functions';
import {
    collection,
    collectionGroup,
    query,
    where,
    getDocs,
    setDoc,
    orderBy,
    limit, 
    startAt,
    startAfter,
    endAt,
    connectFirestoreEmulator
} from "firebase/firestore";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import moment from 'moment';
import { getFirestore, getDoc, doc, addDoc, updateDoc,deleteDoc } from "firebase/firestore";
import { encrypt } from "../crypto";

class FirebaseDataBackend {
    db = null;
    functions = null;
    auth = null;
    app = null;

    constructor() {
       
    }

    /**
     * Get Data by query Fibrebase
     */
    getListData = async (table, conditions) => {
        let queryParams = [];
        for(const condition of conditions) {
            if(condition.param_type === 'where'){
                queryParams.push(where(condition.field_name, condition.operator, condition.value));
            } else if (condition.param_type === 'orderBy') {
                queryParams.push(orderBy(condition.field_name, condition.operator));
            } else if (condition.param_type === 'limit') {
                queryParams.push(limit(condition.value));
            } else if (condition.param_type === 'startAt') {
                queryParams.push(startAt(condition.value));
            } else if (condition.param_type === 'startAfter') {
                queryParams.push(startAfter(condition.value));
            } else if (condition.param_type === 'endAt') {
                queryParams.push(endAt(condition.value));
            }
        }
        let q = query(collection(this.db, table), ...queryParams);

        const querySnapshot = await getDocs(q);
        const resultData = querySnapshot.docs;
        return resultData.map(doc => {
            const { id } = doc
            const data = doc.data()
            return { id, ...data }
        })
    }

    getCollectionGroupData = async (group, conditions) => {
        let queryParams = [];
        for(const condition of conditions) {
            if(condition.param_type === 'where'){
                queryParams.push(where(condition.field_name, condition.operator, condition.value));
            } else if (condition.param_type === 'orderBy') {
                queryParams.push(orderBy(condition.field_name, condition.operator));
            } else if (condition.param_type === 'limit') {
                queryParams.push(limit(condition.value));
            } else if (condition.param_type === 'startAt') {
                queryParams.push(startAt(condition.value));
            } else if (condition.param_type === 'startAfter') {
                queryParams.push(startAfter(condition.value));
            } else if (condition.param_type === 'endAt') {
                queryParams.push(endAt(condition.value));
            }
        }

        let q = query(collectionGroup(this.db, group), ...queryParams);

        const querySnapshot = await getDocs(q);
        const resultData = querySnapshot.docs;
        return resultData.map(doc => {
            const { id } = doc
            const data = doc.data()
            return { id, ...data }
        })
    }

    /**
     * Get Data by ID Fibrebase
     */
    getDocData = async (table, id) => {
        return new Promise((resolve, reject) => {
            const docRef = doc(this.db, table, id);

            getDoc(docRef)
            .then(docSnap => {
                if(docSnap.exists()) {
                    resolve(docSnap.data());
                } else {
                    reject("No Data Retrieved");
                }
            }).catch(error => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * set Data by ID Fibrebase
     */
    setData = async(table, id, data) => {
        return new Promise((resolve, reject) => {
            const finalData = {
                ...data,
                updated_at: moment().unix(),
            }
            setDoc(
                doc(this.db, table, id),
                finalData
            ).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            });
        });
    }

    updateData = async(table, id, data) => {
        return new Promise((resolve, reject) => {
            const finalData = {
                ...data,
                updated_at: moment().unix(),
            }
            updateDoc(
                doc(this.db, table, id),
                finalData
            ).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            });
        });
    }
    
    addData = async(table, data) => {
        return new Promise((resolve, reject) => {
            const finalData = {
                ...data,
                updated_at: moment().unix(),
                created_at: moment().unix(),
            }
            addDoc(collection(this.db, table), finalData)
            .then(docRef => {
                resolve(docRef);
            }).catch((error) => {
                reject(this._handleError(error));
            });
        });
    }

    deleteData = async(table, id) => {
        return new Promise((resolve, reject) => {
            deleteDoc(
                doc(this.db, table, id)
            ).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            });
        });
    }

    updateUserPassword = async(id, password) => {
        return new Promise((resolve, reject) => {
            const encryptedPassword = encrypt(password);
            const updateUserPassword = this._callFirebaseFunction('updateUserPassword');
            updateUserPassword({ id: id, credentials: encryptedPassword })
            .then(result => {
                if (result.data.status === 'OK') {
                    resolve(true);
                } else {
                    reject(this._handleError(result.data.message));
                }
            })
            .catch(error => {
                // const errorCode = error.code;
                reject(this._handleError(error));
            });
        });
    }

    sendBroadcast = async(data) => {
        return new Promise((resolve, reject) => {
            const sendBroadcast = this._callFirebaseFunction('sendBroadcast');
            sendBroadcast(data)
            .then(result => {
                if (result.data.status === 'OK') {
                    resolve(true);
                } else {
                    reject(this._handleError(result.data.message));
                }
            })
            .catch(error => {
                // const errorCode = error.code;
                reject(this._handleError(error));
            });
        });
    }

    getDashboardUserMatricesValue = async (filter) => {
        return new Promise((resolve, reject) => {
            const getDashboardUserMatrices = this._callFirebaseFunction("dashboardUserMatricesValue");
            getDashboardUserMatrices({filter_range: filter})
                .then (result => {
                    if (result.data) {
                        resolve(result);
                    }
                })
                .catch (error => {
                    reject(this._handleError(error));
                });
        });
    }

    getDashboardSalesMatricesValue = async (filter) => {
        return new Promise((resolve, reject) => {
            const getDashboardSalesMatrices = this._callFirebaseFunction("dashboardSalesMatricesValue");
            getDashboardSalesMatrices({filter_range: filter})
                .then (result => {
                    if (result.data) {
                        resolve(result);
                    }
                })
                .catch (error => {
                    reject(this._handleError(error));
                });
        });
    }

    getBroadcastRecipientList = async(filter, daysToSubscriptionExpiry) => {
        return new Promise((resolve, reject) => {
            let queryParam = [];
            if (filter === 'all') {
                queryParam.push({
                    param_type: "where",
                    field_name: "email",
                    operator: "!=",
                    value: ""
                  });
            } else {
                queryParam.push({
                    param_type: "where",
                    field_name: "status",
                    operator: "==",
                    value: "active"
                  });
                queryParam.push({
                    param_type: "where",
                    field_name: "subscribe_news",
                    operator: "==",
                    value: true
                  });
            }
           
            getFirebaseDataBackend().getListData("users", queryParam)
            .then(data => {
                let newData = data;
                if (filter === 'subscribed_with_subscription') {
                    newData = data.filter(row => 
                        row.subscription_count && row.subscription_count > 0
                    )
                } else if (filter === 'subscribed_with_course') {
                    newData = data.filter(row => 
                        row.course_count && row.course_count > 0
                    )
                } else if (filter === 'subscribed_with_subscription_course') {
                    newData = data.filter(row => 
                        row.subscription_count && row.subscription_count > 0 && 
                        row.course_count && row.course_count > 0
                    )
                } else if (filter === 'subscribed_with_subscription_no_course') {
                    newData = data.filter(row => 
                        row.subscription_count && row.subscription_count > 0 && 
                        (!row.course_count || (row.course_count && row.course_count < 1))
                    )
                } else if (filter === 'subscribed_with_course_no_subscription') {
                    newData = data.filter(row => 
                        row.course_count && row.course_count > 0 && 
                        (!row.subscription_count || (row.subscription_count && row.subscription_count < 1))
                    )
                } else if (filter === 'subscribed_no_course_no_subscription') {
                    newData = data.filter(row => 
                        (!row.course_count || (row.course_count && row.course_count < 1)) &&
                        (!row.subscription_count || (row.subscription_count && row.subscription_count < 1))
                    )
                } else if (filter === 'subscribed_subscription_expiring') {
                    const expDate = moment().add(daysToSubscriptionExpiry, "day").startOf("date");
                    newData = data.filter(row => 
                        row.subscription_expiry_date && row.subscription_expiry_date <= expDate.unix()  && row.subscription_expiry_date > moment().unix()                      
                    );
                } else if (filter === 'subscribed_subscription_expired') {
                    newData = data.filter(row => 
                        row.subscription_expiry_date && row.subscription_expiry_date <= moment().unix()                            
                    );
                } 
                resolve(newData);
            })
            .catch(error => {
                // const errorCode = error.code;
                reject(this._handleError(error));
            });
        });
    }


    /**
     * Handle the error
     * @param {*} error 
     */
    _handleError(error) {
        let errorMessage = error;
        if(error.message){
            errorMessage = error.message;
        }
        return errorMessage;
    }

    /**
     * Call firebase function
     * @param {*} error 
     */
     _callFirebaseFunction(functionName, functionRegion) {
        this.functions = (functionRegion && functionRegion !=="") ?  getFunctions(getApp(), functionRegion) : this.functions;
        return httpsCallable(this.functions, functionName);
    }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config 
 */
const initFirebaseDataBackend = () => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseDataBackend();

        _fireBaseBackend.db = getFirestore();
        _fireBaseBackend.auth = getAuth();
        _fireBaseBackend.app = getApp();
        _fireBaseBackend.functions = getFunctions(getApp(), 'asia-southeast2');
        // connectFunctionsEmulator(_fireBaseBackend.functions, 'localhost', 5001);
        if (process.env.VUE_APP_ENVIRONMENT === "local") {
            connectFirestoreEmulator(_fireBaseBackend.db, 'localhost', process.env.VUE_APP_FIRESTORE_EMULATOR_PORT);
            connectFunctionsEmulator(_fireBaseBackend.functions, 'localhost', process.env.VUE_APP_FUNCTIONS_EMULATOR_PORT);
        }
    }
    return _fireBaseBackend;
}

/**
 * Returns the firebase backend
 */
const getFirebaseDataBackend = () => {
    return _fireBaseBackend;
}

export { initFirebaseDataBackend, getFirebaseDataBackend };