<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row mt-3">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-end"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="rawInput"
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control-sm ms-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <!-- Table -->
        <div>
          <div
            v-if="isLoading"
            style="
              text-align: center;
              background-color: white;
              min-height: 200px;
            "
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <b-table
            v-if="!isLoading"
            responsive
            hover
            :items="listData"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDescFlag"
            :filter="filter"
            :filter-included-fields="filterOn"
            sort-icon-left
            @filtered="onFiltered"
          >
            <template #cell(select)="data">
              <div class="text-center">
                <b-checkbox :key="data.item.orderID"></b-checkbox>
              </div>
            </template>
            <template v-if="viewOption" #cell(viewDetailField)="data">
              <router-link
                :to="{
                  name: viewOption.viewRoute,
                  params: { id: data.item.id.toString() },
                }"
              >
                {{ data.item.viewDetailField }}
              </router-link>
            </template>
            <template v-if="editRoute" #cell(action)="data">
              <router-link
                :to="{
                  name: editRoute,
                  params: {
                    id: data.item.id.toString(),
                    pageTitle: editPageTitle,
                  },
                }"
                class="text-success"
              >
                <i class="fas fa-pen"></i>
              </router-link>
            </template>
            <template v-if="secondActionOption" #cell(secondAction)="data">
              <router-link
                :to="{
                  name: secondActionOption.route,
                  params: { id: data.item[secondActionOption.key].toString() },
                }"
                class="text-success"
              >
                <i :class="secondActionOption.iconClass"></i>
              </router-link>
            </template>
            <template v-if="thirdActionOption" #cell(thirdAction)="data">
              <router-link
                :to="{
                  name: thirdActionOption.route,
                  params: { id: data.item[thirdActionOption.key].toString() },
                }"
                class="text-success"
              >
                <i :class="thirdActionOption.iconClass"></i>
              </router-link>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Advanced",
      items: [
        {
          text: "Tables",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      rawInput: "",
      sortDescFlag: false,
      currentRoute: this.$route.path,
      isLoading: true,
      newFields: [],
    };
  },
  computed: {
    rows() {
      return this.listData.length ?? 0;
    },
  },
  props: [
    "fields",
    "listData",
    "sortDesc",
    "sortBy",
    "editRoute",
    "editPageTitle",
    "viewOption",
    "secondActionOption",
    "thirdActionOption",
    "isBusy",
    "filterFields",
  ],
  mounted() {
    this.totalRows = this.items.length;
    this.sortDescFlag = this.sortDesc ? this.sortDesc : false;
    if (this.filterFields) {
      this.filterOn = this.filterFields;
    }
  },
  created() {
    // Use a non-reactive property to prevent
    // unnecessary re-renders of your component
    this.$_timeout = null;
  },
  beforeDestroy() {
    // Ensure that any pending timeout is cleared on destroy
    clearTimeout(this.$_timeout);
  },
  watch: {
    rawInput(newVal) {
      clearTimeout(this.$_timeout);
      this.$_timeout = setTimeout(() => {
        this.filter = newVal;
      }, 500); // set this value to your preferred debounce timeout
    },
    listData() {
      this.isLoading = false;
    },
  },
  methods: {
    onSubmit() {
      //do something
      // this.filter =
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.c-sm {
  min-width: 120px;
  max-width: 120px;
}

.c-md {
  min-width: 180px;
  max-width: 180px;
}

.c-lg {
  min-width: 300px;
  max-width: 300px;
}

.c-currency {
  text-align: right;
}

.c-action {
  text-align: center;
}
</style>