<script>
import { required, url } from "vuelidate/lib/validators";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { initFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import { mapState } from "vuex";

export default {
  page: {
    title: `Course Material -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  validations: {
    courseMaterial: {
      $each: {
        materialURL: {
          required,
          url,
        },
        materialName: {
          required,
        },
      },
    },
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      title: "Add New Course Material",
      isUpdateMode: false,
      isSubmit: false,
      lineNo: 1,
      options: ["Video", "Document", "Link"],
    };
  },
  props: ["id", "pageTitle"],
  computed: {
    ...mapState("courseMaterial", ["courseMaterial"]),
  },
  methods: {
    updateCourseMaterial(value, type, index) {
      this.$store.commit("courseMaterial/UPDATE_COURSE_MATERIAL_LINE", {
        value,
        type,
        index,
      });
    },
    addNewLine(newLineIndex) {
      this.$store.commit(
        "courseMaterial/ADD_COURSE_MATERIAL_LINE",
        newLineIndex
      );
    },
    removeLine(index) {
      this.$store.commit("courseMaterial/DELETE_COURSE_MATERIAL_LINE", index);
    },

    save() {
      this.isSubmit = true;
      this.$v.$touch();

      if (!this.$v.courseMaterial.$invalid) {
        this.$store.dispatch("courseMaterial/saveCourseMaterial");
      }
    },
  },

  created() {
    initFirebaseDataBackend();
    this.$store.commit("courseMaterial/RESET_COURSE_MATERIAL");
    this.$store.commit("courseMaterial/RESET_REMOVED_COURSE_MATERIAL");

    let courseID = "";

    if (this.id) {
      this.isUpdateMode = true;
      courseID = this.id;
    } else {
      this.isUpdateMode = false;
      courseID = this.$store.getters["courseMaterial/getCourseID"];
    }

    if (!courseID) {
      // return to last page
      this.$router.go(-1);
    } else {
      if (this.isUpdateMode) {
        // retrieve data from firebase
        this.$store.commit("courseMaterial/SET_COURSE_ID", courseID);
        this.$store.dispatch("courseMaterial/loadCourseMaterial", courseID);
      } else {
        // init first empty line, new line index hardcoded 0
        this.addNewLine(0);
      }
    }
  },
};
</script>
<template>
  <div>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row px-3">
              <div class="col-4 py-1 px-1">
                <strong> Material Name </strong>
              </div>
              <div class="col-2 py-1 px-1">
                <strong> Material Type </strong>
              </div>
              <div class="col-6 py-1 px-1">
                <strong> URL </strong>
              </div>
            </div>
            <form @submit.prevent="save">
              <div
                class="row px-3"
                v-for="(material, index) in courseMaterial"
                :key="index"
              >
                <div class="col-4 py-1 px-1">
                  <b-form-input
                    :value="material.materialName"
                    @input="updateCourseMaterial($event, 'materialName', index)"
                    type="text"
                    :class="{
                      'is-invalid':
                        isSubmit &&
                        $v.courseMaterial.$each[index].materialName.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="
                      isSubmit &&
                      $v.courseMaterial.$each[index].materialName.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.courseMaterial.$each[index].materialName.required
                      "
                      >This value is required.</span
                    >
                  </div>
                </div>
                <div class="col-2 py-1 px-1">
                  <multiselect
                    :value="material.materialType"
                    @input="updateCourseMaterial($event, 'materialType', index)"
                    :options="options"
                    selectLabel=""
                  ></multiselect>
                </div>
                <div class="col-5 py-1 px-1">
                  <b-form-input
                    :value="material.materialURL"
                    @input="updateCourseMaterial($event, 'materialURL', index)"
                    type="url"
                    placeholder="https://player.vimeo.com/video/12345678"
                    :class="{
                      'is-invalid':
                        isSubmit &&
                        $v.courseMaterial.$each[index].materialURL.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="
                      isSubmit &&
                      $v.courseMaterial.$each[index].materialURL.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.courseMaterial.$each[index].materialURL.required
                      "
                      >This value is required.</span
                    >
                    <span v-if="!$v.courseMaterial.$each[index].materialURL.url"
                      >This value should be a valid url.</span
                    >
                  </div>
                </div>
                <div
                  class="col-1 d-flex justify-content-center align-items-center"
                >
                  <span
                    class="text-success px-1 action"
                    id="add-line"
                    @click="addNewLine(index + 1)"
                    ><i class="fas fa-plus"></i
                  ></span>
                  <b-tooltip target="add-line" delay="500" variant="secondary"
                    >Add Line</b-tooltip
                  >
                  <span
                    class="text-danger px-2 action"
                    id="remove-line"
                    @click="removeLine(index)"
                    ><i class="far fa-trash-alt"></i
                  ></span>
                  <b-tooltip
                    target="remove-line"
                    variant="secondary"
                    delay="500"
                    >Remove Line</b-tooltip
                  >
                </div>
              </div>
              <div class="row">
                <div class="col d-flex justify-content-between">
                  <b-button type="button" variant="primary" @click="$router.go(-1)">Back</b-button>
                  <b-button type="submit" variant="danger">Save</b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.action {
  cursor: pointer;
}
</style>