<script>
export default {
  data () {
    return {
      appName: process.env.VUE_APP_NAME,
      appVersion: process.env.VUE_APP_VERSION
    }
  }
};
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          {{ new Date().getFullYear() }} © {{appName}}. All Rights Reserved.
        </div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">Released v{{appVersion}}</div>
        </div>
      </div>
    </div>
  </footer>
</template>