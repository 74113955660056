import store from '@/state/store'

export default [{
  path: '/login',
  name: 'login',
  component: () => import('../views/pages/account/login'),
  meta: {
    beforeResolve(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({
          name: 'home'
        })
      } else {
        // Continue to the login page
        next()
      }
    },
  },
},
{
  path: '/register',
  name: 'register',
  component: () => import('../views/pages/account/register'),
  meta: {
    beforeResolve(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({
          name: 'home'
        })
      } else {
        // Continue to the login page
        next()
      }
    },
  },
},
{
  path: '/forgot-password',
  name: 'Forgot-password',
  component: () => import('../views/pages/account/forgot-password'),
  meta: {
    beforeResolve(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({
          name: 'home'
        })
      } else {
        // Continue to the login page
        next()
      }
    },
  },
},
{
  path: '/logout',
  name: 'logout',
  meta: {
    authRequired: true,
    beforeResolve(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut');

      const authRequiredOnPreviousRoute = routeFrom.matched.some(
        (route) => route.push('/login')
      )
      // Navigate back to previous page, or home as a fallback
      next(authRequiredOnPreviousRoute ? {
        name: 'home'
      } : {
        ...routeFrom
      })
    },
  },
}, {
  path: '/',
  name: 'home',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/dashboard/index')
},
{
  path: '/subscriptions/list',
  name: 'subscriptions-list',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/subscription/SubscriptionList')
},
{
  path: '/subscriptions/edit/:id',
  name: 'subscriptions-detail',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/subscription/SubscriptionDetail')
},
{
  path: '/subscriptions/new',
  name: 'subscriptions-new',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/subscription/SubscriptionDetail')
},
{
  path: '/courses/list',
  name: 'courses-list',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/course/CourseList')
},
{
  path: '/courses/new',
  name: 'course-new',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/course/Course'),
  children: [
    {
      path: 'course-detail', name: 'course-detail', meta: {
        authRequired: true,
      }, component: () => import('../views/pages/course/CourseDetail')
    },
    {
      path: 'course-material', name: 'course-material', meta: {
        authRequired: true,
      }, component: () => import('../views/pages/course/CourseMaterial')
    },
  ],

},
{
  path: '/courses/edit',
  name: 'course-edit',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/course/Course'),
  children: [
    {
      path: 'course-detail/:id', name: 'course-detail-edit', props: true, meta: {
        authRequired: true,
      }, component: () => import('../views/pages/course/CourseDetail')
    },
    {
      path: 'course-material/:id', name: 'course-material-edit', props: true, meta: {
        authRequired: true,
      }, component: () => import('../views/pages/course/CourseMaterial')
    },
  ],
},

{
  path: '/user/list',
  name: 'user-list',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserList')
},
{
  path: '/user/edit/:id',
  name: 'user-detail',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserDetail')
},
{
  path: '/user/new',
  name: 'user-new',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserDetail')
},
{
  path: '/order/list',
  name: 'order-list',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/order/OrderList')
},
{
  path: '/order/view/:id',
  name: 'order-detail',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/order/OrderDetail')
},
{
  path: '/order/new',
  name: 'order-new',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/order/OrderDetail')
},
{
  path: '/order/:id/payment/',
  name: 'order-payment',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/order/Payment')
},
{
  path: '/payment/list',
  name: 'payment',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/order/Payment')
},
{
  path: '/user-course/list',
  name: 'user-course-list',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserCourseList')
},
{
  path: '/user-course/edit/:id',
  name: 'user-course-detail',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserCourseDetail')
},
{
  path: '/user-course/cancellation/:id',
  name: 'user-course-cancellation',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserCourseCancellation')
},
{
  path: '/user-course/new',
  name: 'user-course-new',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserCourseDetail')
},
{
  path: '/user-subscription/list',
  name: 'user-subscription-list',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserSubscriptionList')
},
{
  path: '/user-subscription/edit/:id',
  name: 'user-subscription-detail',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserSubscriptionDetail')
},
{
  path: '/user-subscription/cancellation/:id',
  name: 'user-subscription-cancellation',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserSubscriptionCancellation')
},
{
  path: '/user-subscription/new',
  name: 'user-subscription-new',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/user/UserSubscriptionDetail')
},
{
  path: '/broadcast/list/email',
  name: 'broadcast-list-email',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/broadcast/BroadcastEmailList')
},
{
  path: '/broadcast/list/sms',
  name: 'broadcast-list-sms',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/broadcast/BroadcastSmsList')
},
{
  path: '/broadcast/new',
  name: 'broadcast-new',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/broadcast/BroadcastDetail')
},
{
  path: '/broadcast/edit/:id',
  name: 'broadcast-detail',
  props: true,
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/broadcast/BroadcastDetail')
},
]