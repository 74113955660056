<script>
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import * as courseFormSchema from "../../../forms/courseFormSchema";
import moment from "moment";
import Swal from "sweetalert2";
import { initFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import { getFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
// import { getAuth } from 'firebase/auth';
import resizebase64 from "resize-base64";

export default {
  page: {
    title: `Course Details -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  props: ["id", "pageTitle"],
  data() {
    return {
      title: "Add New Course",
      model: null,
      returnCourseList: [],
      schema: null,
      courseDetailCount: 4,
      learningOutcomeCount: 4,
      isSaving: false,
      formOptions: {
        validateAfterChanged: true,
        validateAfterLoad: false,
      },
      editorOption: {
        theme: "snow",
      },
    };
  },
  methods: {
    initialModelState() {
      this.model = {
        name: "",
        short_desc: "",
        payment_desc: "",
        long_desc: "",
        price: 0,
        enable_discounted_price: false,
        code: "",
        discounted_price: 0,
        suitable_for: "everyone",
        type: "general",
        category: "course",
        sales_start_date: moment().add(1, "d").unix() * 1000,
        sales_end_date: 0,
        start_date: moment().add(1, "d").unix() * 1000,
        end_date: 0,
        total_seats: -1,
        seats_sold_inception: 0,
        preview_video_url: "",
        preview_document_url: "",
        course_detail: "",
        learning_outcome: "",
        status: "active",
        bundle_course_list: [],
        image: "",
      };
    },
    submitCourseDetails() {
      const courseData1 = JSON.parse(JSON.stringify(this.model));
      courseData1.sales_start_date = courseData1.sales_start_date / 1000;
      courseData1.sales_end_date = courseData1.sales_end_date / 1000;
      courseData1.start_date = courseData1.start_date / 1000;
      courseData1.end_date = courseData1.end_date / 1000;
      const courseId = this.id ? this.id : null;
      courseData1.learning_outcome = courseData1.learning_outcome.split("\n");
      courseData1.course_detail = courseData1.course_detail.split("\n");

      const bundleListTemp = [];
      for (const row of courseData1.bundle_course_list) {
        bundleListTemp.push(row.id);
      }
      courseData1.bundle_course_list = bundleListTemp;

      if (courseData1.image || courseData1.image != "") {
        courseData1.image = resizebase64(courseData1.image, 500, 500);
      }

      if (courseId) {
        getFirebaseDataBackend()
          .updateData("courses", courseId, courseData1)
          .then(() => {
            // Add a new document in collection "cities"
            Swal.fire("Updated!", "Courses Updated.", "success");
            this.$router.push({name: 'course-material-edit'});
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Failed!", "Courses failed to update.", "error");
          });
      } else {
        getFirebaseDataBackend()
          .addData("courses", courseData1)
          .then((response) => {
            // Add a new document in collection "cities"
            if (response.id) {
              Swal.fire("Created!", "Courses Created.", "success");
              this.$store.commit("courseMaterial/SET_COURSE_ID", response.id);
              this.$router.push({name: 'course-material'});
            } else {
              Swal.fire(
                "No Course ID",
                "Course created but no course ID return",
                "error"
              );
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Failed!", "Courses failed to create.", "error");
          });
      }
    },
    async getCourseDetail() {
      try {
        if (this.id) {
          const data = await getFirebaseDataBackend().getDocData(
            "courses",
            this.id
          );
          if (data) {
            data.sales_start_date = data.sales_start_date * 1000;
            data.sales_end_date = data.sales_end_date * 1000;
            data.start_date = data.start_date * 1000;
            data.end_date = data.end_date * 1000;

            const model1Keys = Object.keys(this.model);
            for (const row of model1Keys) {
              if (data[row]) {
                this.model[row] = data[row];
              }
            }

            if (
              this.model.learning_outcome &&
              Array.isArray(this.model.learning_outcome) &&
              this.model.learning_outcome.length > 0
            ) {
              this.learningOutcomeCount = this.model.learning_outcome.length;
              this.model.learning_outcome =
                this.model.learning_outcome.join("\n");
            }

            if (
              this.model.course_detail &&
              Array.isArray(this.model.course_detail) &&
              this.model.course_detail.length > 0
            ) {
              this.courseDetailCount = this.model.course_detail.length;
              this.model.course_detail =
                this.model.course_detail.join("\n");
            }

            const queryParam = [
              {
                param_type: "where",
                field_name: "status",
                operator: "==",
                value: "active",
              },
            ];

            const bundleListTemp = [];
            const courseList = await getFirebaseDataBackend().getListData(
              "courses",
              queryParam
            );
            for (const row of courseList) {
              this.returnCourseList.push({ id: row.id, name: `${row.name}` });
              if (
                this.model.bundle_course_list &&
                this.model.bundle_course_list.length > 0
              ) {
                if (this.model.bundle_course_list.includes(row.id)) {
                  bundleListTemp.push({ id: row.id, name: row.name });
                }
              }
            }
            this.model.bundle_course_list = bundleListTemp;
          }
        }
      } catch (error) {
        // console.log(error.message)
      }
    },
  },
  computed: {
    editor() {
      return this.$refs.quillEditor.quill;
    },
  },
  created() {
    initFirebaseDataBackend();
  },
  async mounted() {
    this.initialModelState();

    if (this.$route.name.includes("new")) {
      this.title = "Add Course Details";
    } else {
      if (this.id) {
        this.title = "Edit Course Details";
        await this.getCourseDetail();
      }
    }
    this.schema = courseFormSchema.getSchema(
      this.returnCourseList,
      this.learningOutcomeCount,
      this.courseDetailCount
    );
  },
};
</script>
<template>
  <div>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="card">
              <form action="" @submit.prevent="submitCourseDetails">
                <vue-form-generator
                  tag="div"
                  :schema="schema"
                  :options="formOptions"
                  :model="model"
                />
                <div class="d-flex justify-content-end px-4 pb-3">
                  <button type="submit" class="btn btn-primary btn-lg px-4">
                    <!-- {{ isSaving ? "Saving..." : "Submit" }} -->
                    Save & Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>