// import VueFormGenerator from 'vue-form-generator';
// import moment from 'moment';
// import { initFirebaseDataBackend } from '../helpers/firebase/dataUtils';
// import { getFirebaseDataBackend } from '../helpers/firebase/dataUtils';

export const getSchema = () => {
  return {
    groups: [
      {
        legend: 'Basic Information',
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Order ID',
            model: 'order_id',
            disabled: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'User Email',
            model: 'user_email',
            disabled: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'User Mobile No',
            model: 'user_mobile_no',
            disabled: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Subscription Name',
            model: 'subscription_name',
            disabled: true,
            validator: ['required']
          },
          {
            type: "input",
            inputType: "date",
            label: "Subscription Expiry",
            placeholder: "SSubscription Expiry",
            model: "expiry_date",
            disabled: true,
            validator: ['required','date'],
            set: function(model, val) {
                model.expiry_date = new Date(val).valueOf();
            }
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Price',
            model: 'price',
            disabled: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Cancellation Remark',
            model: 'cancellation_remark',
            required: true,
            validator: ['required']
          },
        ]
      },
      
    ]
  }
};