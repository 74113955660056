<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Stat from "@/components/widgets/stat";
// import SalesAnalytics from "./sales-analytics";
// import SellingProduct from "./selling-product";
// import TopUsers from "./top-users";
// import Activity from "./activity";
// import SocialSource from "./social-source";

export default {
  page: {
    title: `Dashboard -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    // SalesAnalytics,
    // SellingProduct,
    // TopUsers,
    // Activity,
    // SocialSource,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: process.env.VUE_APP_NAME,
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Stat />
    <div class="row">
      <!-- <SalesAnalytics />-->
      <div class="col-xl-4">
        
        <!-- <SellingProduct /> -->
      </div>
      
    </div>
    <div class="row">
    <!--
      <TopUsers />
      <Activity />
      <SocialSource />
    -->
    </div>
  </Layout>
</template>
