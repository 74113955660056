<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Listing from "@/components/listing";
import appConfig from "@/app.config";
import { initFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import { getFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  page: {
    title: `Broadcast SMS List -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Listing,
  },
  data() {
    return {
      title: "Broadcast SMS List",
      items: [
        {
          text: "Broadcast SMS",
        },
        {
          text: "List",
          active: true,
        },
      ],
      fields: [
        {
          key: "batch_id",
          sortable: true,
        },
        {
          key: "processed_date",
          sortable: true,
        },
        {
          key: "source",
          sortable: true,
        },
        {
          key: "to",
          label: "recipient mobile no",
          sortable: true,
        },
        {
          key: "status",
          label: "SMS Status",
          sortable: true,
        },
        {
          key: "sms_sent_date",
          label: "SMS Sent Date",
          sortable: true,
        },
        {
          key: "processed_date",
          label: "Processed Date",
          sortable: true,
        },
        {
          key: "template.name",
          label: "sms template",
          sortable: true,
        },
      ],
      listData: [],
      exportFieldName: [],
      sortDesc: true,
    };
  },
  methods: {
    async getBroadcastList() {
      try {
        const queryParam = [
          {
            param_type: "where",
            field_name: "batch_id",
            operator: "!=",
            value: "",
          },
          {
            param_type: "orderBy",
            field_name: "batch_id",
            operator: "asc",
          },
          {
            param_type: "limit",
            value: 1000,
          },
        ];
        const resultData = await getFirebaseDataBackend().getListData(
          "sms_sent",
          queryParam
        );
        if (resultData) {
          resultData.map((data) => {
            data.original_processed_date = Number(data.processed_date) ?? 0;
            data.processed_date = moment
                .unix(data.processed_date)
                .format("DD/MM/YYYY HH:mm:SS") ?? moment()
                .unix()
                .format("DD/MM/YYYY HH:mm:SS");

            data.sms_sent_date = data.messagebird_result.statusDatetime
              ? moment
                  .unix(data.messagebird_result.statusDatetime)
                  .format("DD/MM/YYYY HH:mm:SS")
              : moment()
                .unix()
                .format("DD/MM/YYYY HH:mm:SS");
          
            data.status = data.messagebird_result.status ?? "";
            data.templateName = data.template.name ?? "";
          });
          this.listData = resultData;
        }
      } catch (error) {
        console.log(error.message);
        Swal.fire("Error!", "Unable to retrieve Data", "error");
      }
    },
    generateExportFields() {
      this.exportFieldName.push("id");
      for (const row of this.fields) {
        if (row.key !== "action") {
          this.exportFieldName.push(row.key);
        }
      }
      // add additional fields
      this.exportFieldName.push("status");
      this.exportFieldName.push("attempts");
      this.exportFieldName.push("templateName");
    },
  },
  mounted() {
    initFirebaseDataBackend();
    this.getBroadcastList();
    this.generateExportFields();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-inline-flex">
      <div>
        <download-csv
          :data="listData"
          :fields="exportFieldName"
          :name="'broadcast_sms_list.csv'"
        >
          <b-button variant="primary">Download CSV</b-button>
        </download-csv>
      </div>
    </div>
    <div class="mt-3">
      <Listing
        :fields="fields"
        :listData="listData"
        :sortBy="'original_processed_date'"
        :sortDesc="sortDesc"
      />
    </div>
  </Layout>
</template>
