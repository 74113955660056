// import VueFormGenerator from 'vue-form-generator';
// import moment from 'moment';
// import { initFirebaseDataBackend } from '../helpers/firebase/dataUtils';
// import { getFirebaseDataBackend } from '../helpers/firebase/dataUtils';

export const getSchema = (smsTemplate, emailTemplate) => {
  return {
    groups: [
      {
        legend: 'Basic Information',
        fields: [
          {
            type: "vueMultiSelect",
            label: "Course",
            placeholder: "Select a broadcast method",
            model: "broadcast_method",
            required: true,
            validator: ['required'],
            values: () => {
              return [
                {id:"sms", name: "SMS"},
                {id:"email", name: "Email"}
              ]
            },
            selectOptions: {
              multiSelect: false,
              closeOnSelect: true,
              searchable: true,
              showLabels: true,
              key: "id",
              label: "name",
            },
          },
          {
            type: "vueMultiSelect",
            label: "Broadcast Filter",
            placeholder: "Select broadcast filter",
            model: "broadcast_filter",
            required: true,
            validator: ['required'],
            values: () => {
              return [
                {id:"subscribed", name: "Subscribed To News"},
                {id:"subscribed_with_subscription", name: "Subscribed WITH subscription"},
                {id:"subscribed_with_course", name: "Subscribed WITH course"},
                {id:"subscribed_with_subscription_course", name: "Subscribed WITH subscription & course"},
                {id:"subscribed_with_subscription_no_course", name: "Subscribed WITH subscription, NO course"},
                {id:"subscribed_with_course_no_subscription", name: "Subscribed WITH course, NO subscription"},
                {id:"subscribed_no_course_no_subscription", name: "Subscribed NO subscription, NO course"},
                {id:"subscribed_subscription_expiring", name: "Subscribed with subscription expiring"},
                {id:"subscribed_subscription_expired", name: "Subscribed with subscription expired"},
                {id:"all", name: "All users"},
              ]
            },
            selectOptions: {
              multiSelect: false,
              closeOnSelect: true,
              searchable: true,
              showLabels: true,
              key: "id",
              label: "name",
            },
            visible(model) {
              return model && model.broadcast_method ;
            }
          },
          {
            type: "vueMultiSelect",
            label: "Broadcast Sms Template",
            placeholder: "Select broadcast template",
            model: "broadcast_sms_template",
            required: true,
            validator: ['required'],
            values: () => {
              return [{id: "others", text: ""}, ...smsTemplate]
            },
            selectOptions: {
              multiSelect: false,
              closeOnSelect: true,
              searchable: true,
              showLabels: true,
              key: "id",
              label: "id",
            },
            visible(model) {
              return model && model.broadcast_method &&  model.broadcast_method.id ==='sms';
            },
            onChanged (model, newVal) {
              model.broadcast_template = newVal.text;
              return model;
            }
          },
          {
            type: "vueMultiSelect",
            label: "Broadcast Email Template",
            placeholder: "Select broadcast template",
            model: "broadcast_email_template",
            required: true,
            validator: ['required'],
            values: () => {
              return [{id: "others", html: ""}, ...emailTemplate]
            },
            selectOptions: {
              multiSelect: false,
              closeOnSelect: true,
              searchable: true,
              showLabels: true,
              key: "id",
              label: "id",
            },
            visible(model) {
              return model && model.broadcast_method &&  model.broadcast_method.id ==='email';
            },
            onChanged (model, newVal) {
              model.broadcast_template = (newVal && newVal.html) ? newVal.html : "";
              model.broadcast_subject = (newVal && newVal.subject) ? newVal.subject : "";
              return model;
            }
          },
          {
            type: 'textArea',
            rows:4,
            label: 'Broadcast Template',
            model: 'broadcast_template',
            required: true,
            validator: ['required'],
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Broadcast Subject',
            model: 'broadcast_subject',
            visible(model) {
              return model && (model.broadcast_method &&  model.broadcast_method.id ==='email');
            },
          },
        ]
      },
      
    ]
  }
};
