<script>
import countTo from "vue-count-to";
import { initFirebaseDataBackend } from "../../helpers/firebase/dataUtils";
import { getFirebaseDataBackend } from "../../helpers/firebase/dataUtils";


/**
 * Stat component
 */

export default {
    components: {
        countTo
    },
    methods: {
        dashboardData() {
            this.isSpinnerUserMetric = true;
            this.isSpinnerSalesMetric = true;
            // const dashboardUserData = await getFirebaseDataBackend().getDashboardUserMatricesValue(this.filter);
            // this.userListData = dashboardUserData.data;

            getFirebaseDataBackend().getDashboardUserMatricesValue(this.filter)
                .then(dashboardUserData => {
                    this.userListData = dashboardUserData.data;
                    this.isSpinnerUserMetric = false;
                })

            // const dashboardSalesData = await getFirebaseDataBackend().getDashboardSalesMatricesValue(this.filter);
            // this.salesListData = dashboardSalesData.data;

            getFirebaseDataBackend().getDashboardSalesMatricesValue(this.filter)
                .then(dashboardSalesData => {
                    this.salesListData = dashboardSalesData.data;
                    this.isSpinnerSalesMetric = false;
                })
        },
        onChange() {

            for (const option of this.options) { 
                if (option.value === this.filter) {
                    this.selectedFilterText = option.text
                }
            }
            this.dashboardData();
        },
    },
    mounted() {
        initFirebaseDataBackend();
        this.dashboardData();
    },
    data() {
        return {
            series: [{
                data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
            }],
            chartOptions: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    enabled: false,
                    fixed: {
                        enabled: false
                    },
                    onDatasetHover: {
                        highlightDataSeries: false,
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            growthChartOptions: {
                fill: {
                    colors: ["#f1b44c"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    enabled: false,
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            orderseries: [70],
            orderRadial: {
                fill: {
                    colors: ["#34c38f"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            },
            customerseries: [55],
            customerRadial: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        }
                    }
                }
            },
            userListData: {
                new_member: {
                    count: 0,
                    compare_flag: "",
                    percentage: 0
                },
                expired_subscription: {
                    count: 0,
                    compare_flag: "",
                    percentage: 0
                },
                expiring_subscription: {
                    count: 0,
                    compare_flag: "",
                    percentage: 0
                },
                subscription_enrollment: {
                    count: 0,
                    compare_flag: "",
                    percentage: 0
                },
                course_enrollment: {
                    count: 0,
                    compare_flag: "",
                    percentage: 0
                }
            },
            salesListData:  {
                ranged_sales: {
                    amount: 0,
                    amount_comparison_flag: "",
                    amount_difference: 0
                },
                failed_payment: {
                    amount: 0,
                    amount_comparison_flag: "",
                    percentage: 0,
                    count_comparison_flag: 0,
                    count: 0,
                    count_percentage: 0
                },
                total_sales: {
                   amount: 0,
                    amount_comparison_flag: "",
                    percentage: 0,
                    count_comparison_flag: 0,
                    count: 0,
                    count_percentage: 0
                },
                email_sent: {
                    count: 0,
                },
                sms_sent: {
                    count: 0,
                }
            },
            filter: 'today',
            selectedFilterText: 'today',
            options: [
                { text: 'today', value: 'today' },
                { text: 'yesterday', value: 'yesterday' },
                { text: '7 days', value: '7days' },
                { text: '1 month', value: '30days' },
                { text: '6 months', value: '6months' }
            ],
            isSpinnerSalesMetric: false,
            isSpinnerUserMetric: false,
        };
    }
};
</script>

<template>
<div>
    <div align="end">
        <label>Filter by:&nbsp;&nbsp;&nbsp;</label>
        <select v-model="filter" @change="onChange(filter)" variant="primary" class="btn btn-primary mb-2">
            <option v-for="option in options" v-bind:key="option.value" :value="option.value" v-text="option.text"></option>
        </select>
    </div>

    <div class="row row-cols-1 row-cols-md-3 g-4" style="margin-bottom: 1rem">
         
        <div class="col-md-6 col-xl-3">
            <div class="card h-100">
                <div v-if="isSpinnerUserMetric" style="text-align: center;background-color: white;min-height: 150px;">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-if="!isSpinnerUserMetric"  class="card-body">
                    <div class="float-end mt-2">
                        <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart>
                    </div>
                    <div>
                        <span data-plugin="counterup">
                            <h4 class="mb-1 mt-1">
                                <countTo :startVal="0" :endVal="this.userListData.new_member.count" :duration="1500">New Member</countTo>
                            </h4>
                        </span>
                        <span class="text-muted mb-0">New Member</span>
                    </div>
                    <p class="text-muted mt-3 mb-0">
                        <span v-if="this.userListData.new_member.compare_flag === 'increased'" class="text-success me-1">
                            <i class="mdi mdi-arrow-up-bold me-1"></i>
                        </span>
                        <span v-if="this.userListData.new_member.compare_flag === 'no_change'" class="text-success me-1">
                            <i class="mdi mdi-arrow-up-down-bold me-1"></i>
                        </span>
                        <span v-if="this.userListData.new_member.compare_flag === 'decreased'" class="text-danger me-1">
                            <i class="mdi mdi-arrow-down-bold me-1"></i>
                        </span>
                        <countTo :startVal="0" :endVal="this.userListData.new_member.percentage" :duration="1500"></countTo>
                        % since {{this.selectedFilterText}}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3">
            <div class="card h-100">
                <div v-if="isSpinnerUserMetric" style="text-align: center;background-color: white;min-height: 150px;">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-if="!isSpinnerUserMetric"  class="card-body">
                     <div class="float-end mt-2">
                        <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions" :series="series"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">
                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="this.userListData.course_enrollment.count" :duration="1500"></countTo>
                            </span>
                        </h4>
                        <p class="text-muted mb-0">Course Enrollment</p>
                    </div>
                    <p class="text-muted mt-3 mb-0">
                        <span v-if="this.userListData.course_enrollment.compare_flag === 'increased'" class="text-success me-1">
                            <i class="mdi mdi-arrow-up-bold me-1"></i>
                        </span>
                        <span v-if="this.userListData.course_enrollment.compare_flag === 'no_change'" class="text-success me-1">
                            <i class="mdi mdi-arrow-up-down-bold me-1"></i>
                        </span>
                        <span v-if="this.userListData.course_enrollment.compare_flag === 'decreased'" class="text-danger me-1">
                            <i class="mdi mdi-arrow-down-bold me-1"></i>
                        </span>
                        <countTo :startVal="0" :endVal="this.userListData.course_enrollment.percentage" :duration="1500"></countTo>
                        % since {{this.selectedFilterText}}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3">
            <div class="card h-100">
                <div v-if="isSpinnerUserMetric" style="text-align: center;background-color: white;min-height: 150px;">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-if="!isSpinnerUserMetric"  class="card-body">
                   <div class="float-end mt-2">
                        <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">
                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="this.userListData.subscription_enrollment.count" :duration="1500"></countTo>
                            </span>
                        </h4>
                        <p class="text-muted mb-0">Subscription Enrollment</p>
                    </div>
                    <p class="text-muted mt-3 mb-0">
                        <span v-if="this.userListData.subscription_enrollment.compare_flag === 'increased'" class="text-success me-1">
                            <i class="mdi mdi-arrow-up-bold me-1"></i>
                        </span>
                        <span v-if="this.userListData.subscription_enrollment.compare_flag === 'no_change'" class="text-success me-1">
                            <i class="mdi mdi-arrow-up-down-bold me-1"></i>
                        </span>
                        <span v-if="this.userListData.subscription_enrollment.compare_flag === 'decreased'" class="text-danger me-1">
                            <i class="mdi mdi-arrow-down-bold me-1"></i>
                        </span>
                        <countTo :startVal="0" :endVal="this.userListData.subscription_enrollment.percentage" :duration="1500"></countTo>

                        % since {{this.selectedFilterText}}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3">
            <div class="card h-100">
                <div v-if="isSpinnerUserMetric" style="text-align: center;background-color: white;min-height: 150px;">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-if="!isSpinnerUserMetric"  class="card-body">
                    <div class="float-end mt-2">
                        <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">
                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="this.userListData.expired_subscription.count" :duration="1500"></countTo>
                            </span>
                        </h4>
                        <p class="text-muted mb-0">Expired Subscription</p>
                    </div>
                    <br/>
                     <div class="float-end mt-2">
                        <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions" :series="series"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">
                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="this.userListData.expiring_subscription.count" :duration="1500"></countTo>
                            </span>
                        </h4>
                        <p class="text-muted mb-0">Expiring Subscription</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- end row-->

    <div class="row row-cols-1 row-cols-md-3 g-4" style="margin-bottom: 1rem">
        <div class="col-md-6 col-xl-3">
            <div class="card h-100">
                <div v-if="isSpinnerSalesMetric" style="text-align: center;background-color: white;min-height: 150px;">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-if="!isSpinnerSalesMetric" class="card-body">
                    <div class="float-end mt-2">
                        <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">
                            RM
                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="this.salesListData.ranged_sales.amount" :duration="1500"></countTo>
                                 &nbsp;(<countTo :startVal="0" :endVal="this.salesListData.ranged_sales.count" :duration="1500"></countTo>)
                            </span>
                        </h4>
                        <span class="text-muted mb-0">Sales</span>
                    </div>
                    <p class="text-muted mt-3 mb-0">
                        <span v-if="this.salesListData.ranged_sales.amount_comparison_flag === 'increased'" class="text-success me-1">
                            <i class="mdi mdi-arrow-up-bold me-1"></i>
                        </span>
                        <span v-if="this.salesListData.ranged_sales.amount_comparison_flag === 'decreased'" class="text-danger me-1">
                            <i class="mdi mdi-arrow-down-bold me-1"></i>
                        </span>
                        RM
                        <countTo :startVal="0" :endVal="this.salesListData.ranged_sales.amount_difference" :duration="1500"></countTo>
                        since {{this.selectedFilterText}}
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3">
            <div class="card h-100">
                <div v-if="isSpinnerSalesMetric" style="text-align: center;background-color: white;min-height: 150px;">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-if="!isSpinnerSalesMetric" class="card-body">
                    <div class="float-end mt-2">
                        <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">
                            RM
                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="this.salesListData.total_sales.amount" :duration="1500"></countTo>
                                &nbsp;(<countTo :startVal="0" :endVal="this.salesListData.total_sales.count" :duration="1500"></countTo>)
                            </span>
                        </h4>
                        <span class="text-muted mb-0">Total Sales since Inception</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-6 col-xl-3">
            <div class="card h-100">
                <div v-if="isSpinnerSalesMetric" style="text-align: center;background-color: white;min-height: 150px;">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-if="!isSpinnerSalesMetric" class="card-body">
                    <div class="float-end mt-2">
                        <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">
                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="this.salesListData.email_sent.count" :duration="1500"></countTo>
                            </span>
                        </h4>
                        <span class="text-muted mb-0">Email Sent</span>
                        since inception
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-3">
            <div class="card h-100">
               <div v-if="isSpinnerSalesMetric" style="text-align: center;background-color: white;min-height: 150px;">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-if="!isSpinnerSalesMetric" class="card-body">
                    <div class="float-end mt-2">
                        <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="chartOptions" :series="series"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">
                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="this.salesListData.sms_sent.count" :duration="1500"></countTo>
                            </span>
                        </h4>
                        <span class="text-muted mb-0">SMS Sent</span>
                        since inception
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
