// import VueFormGenerator from 'vue-form-generator';
// import moment from 'moment';
// import { initFirebaseDataBackend } from '../helpers/firebase/dataUtils';
// import { getFirebaseDataBackend } from '../helpers/firebase/dataUtils';

export const getSchema = (subscriptionList, userList) => {
  return {
    groups: [
      {
        legend: 'Basic Information',
        fields: [
          {
            type: "vueMultiSelect",
            label: "Subscription",
            placeholder: "Select a subscription",
            model: "subscription",
            required: true,
            validator: ['required'],
            values: () => {
              return subscriptionList;
            },
            selectOptions: {
              multiSelect: false,
              closeOnSelect: true,
              searchable: true,
              showLabels: true,
              key: "id",
              label: "name",
            },
            // set: function(model, val) {
            //   model.subscription_id = val.id;
            // }
          },
          {
            type: "vueMultiSelect",
            label: "User",
            placeholder: "Select a user",
            model: "user",
            required: true,
            validator: ['required'],
            values: () => {
              return userList;
            },
            selectOptions: {
              multiSelect: false,
              closeOnSelect: true,
              searchable: true,
              showLabels: true,
              key: "id",
              label: "name",
            },
            // set: function(model, val) {
            //   model.user_id = val.id;
            // }
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Price',
            model: 'price',
            min: 0,
            step: "0.01",
            required: true,
            validator: ['required']
          },
          {
            type: 'select',
            label: 'type',
            model: 'type',
            values: function () {
              return [
                {id:"gift", name: "gift"},
                {id:"referral_reward", name: "referral_reward"},
                {id:"reimbursement", name: "reimbursement"},
                {id:"replacement", name: "replacement"},
              ];
            },
            default:"gift",
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Remark',
            model: 'entry_remark',
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Custom Message to User',
            model: 'custom_message',
            required: true,
            validator: ['required']
          },
        ]
      },
      
    ]
  }
};