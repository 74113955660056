<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import * as userCourseCancellationSchema from '../../../forms/userCourseCancellationSchema';
import moment from 'moment';
import Swal from "sweetalert2";
import { initFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
import { getFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';

export default {
  page: {
    title: "User Subcription Cancellation",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,

  },
  props: ["id","pageTitle"],
  data() {
    return {
      title: "User Subcription Cancellation",
      model: null,
      schema: null,
      isSaving: false,
      formOptions: {
        validateAfterChanged: true,
        validateAfterLoad: false,
      },
      subscriptionData: null,
      mySubscriptionUrl: ""
    };
  },
  methods: {
    initialModelState() {
      this.model = { 
        user_email: '',
        user_mobile_no: '',
        course_name: '',
        order_id: '',
        course_end_date: 0,
        id: '',
        price: 0,
        cancellation_remark: "",
      };
    },
    async getUserCourseDetails() {
      try {
        if(this.id){
          const data = await getFirebaseDataBackend().getDocData("user_course", this.id);
          if(data) {
            this.model.id = data.id;
            this.model.user_email = data.user_email;
            this.model.user_id = data.user_id;
            this.model.user_mobile_no = data.user_mobile_no;
            this.model.course_name = data.course_name;
            this.model.order_id = data.order_id;
            this.model.course_end_date = data.course_end_date * 1000;
            this.model.price = data.price;
            this.model.cancellation_remark = "";
          }
        }
      } catch(error) {
        // console.log(error.message)
      }
    },
    async submitCancellationDetails() {
      try{
        const newExpiryDate = moment().startOf("day").unix();
        if ((this.model.course_end_date/1000) > newExpiryDate || this.model.course_end_date === 0) {
          await getFirebaseDataBackend()
          .updateData("orders", this.model.order_id, { status: "cancelled", cancellation_remark: this.model.cancellation_remark  });

          await getFirebaseDataBackend()
          .updateData("user_course", this.id, { status: "cancelled", course_end_date: newExpiryDate, cancellation_remark: this.model.cancellation_remark  });

          Swal.fire("Successfu!", "User Course has been cancelled", "success");
          this.$router.push({
            name: "user-course-list",
          })
        } else {
          Swal.fire("Error!", "User Course expired, cannot be cancelled.", "error");
        }       
      } catch (error) {
        console.log(error);
        Swal.fire("Failed!", "User Course failed to cancel.", "error");
      }
    },
  },
  created() {
    initFirebaseDataBackend();
  },
  mounted() {
    this.initialModelState();
    this.schema = userCourseCancellationSchema.getSchema();
    this.getUserCourseDetails();
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">User Subscription Detail</h4>
            <div class="card ">
              <form action="" @submit.prevent="submitCancellationDetails">
                  <vue-form-generator tag="div" :schema="schema" :options="formOptions" :model="model" />
                  <div class="d-flex justify-content-end mt-3 pr-4">
                      <button type="submit" class="btn btn-primary btn-lg">
                          {{ isSaving ? 'Saving...' : 'Submit'}}
                      </button>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>