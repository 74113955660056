<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import createUserFormSchema from '../../../forms/createUserFormSchema';
import editUserFormSchema from '../../../forms/editUserFormSchema';
import moment from 'moment';
import Swal from "sweetalert2";
import { initFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
import { getFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
// import { getAuth } from 'firebase/auth';

export default {
  page: {
    title: `User Details -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  props: ["id","pageTitle"],
  data() {
    return {
      title: "Add New User",
      model: null,
      schema: null,
      isSaving: false,
      formOptions: {
        validateAfterChanged: true,
        validateAfterLoad: false,
      },
     
    };
  },
  methods: {
    initialModelState() {
      this.model = { 
            first_name: '',
            last_name: '',
            gender: '',
            email: '',
            dob: moment().subtract(18,'y').unix() * 1000,
            // dob: null,
            mobile_no: '',
            password: '',
            subscribe_news: true,
            status: "inactive"
      };
    },
    submitUserDetails() {
     const userData = {
       email: this.model.email,
       mobile_no: this.model.mobile_no.replace(/\s/g, ""),
       first_name: this.model.first_name,
       last_name: this.model.last_name,
       gender: this.model.gender,
       dob: this.model.dob/1000,
       subscribe_news: this.model.subscribe_news,
       status: this.model.status,
       is_admin: this.model.is_admin ?? false
     }

      if(!this.id){
        this.$store.dispatch("auth/adminRegisterUser", {
          email: this.model.email,
          password: this.model.password,
        })
        // eslint-disable-next-line no-unused-vars
        .then((user) => {
          getFirebaseDataBackend()
          .setData("users", user.uid, userData)
            .then(() => {
              // Add a new document in collection "cities"
              Swal.fire("Created!", "User Created.", "success");
            })
            .catch(() => {
              // console.log(error)
              Swal.fire("Failed!", "User failed to create.", "error");
            });          
        })
        .catch((error) => {
          Swal.fire("Failed!", error, "error");
        })
      } else {
        //Update password then update user
        if(this.model.password && this.model.password !== ""){
          getFirebaseDataBackend()
          .updateUserPassword(this.id, this.model.password)
            .then(() => {
              getFirebaseDataBackend()
              .updateData("users", this.id, userData)
                .then(() => {
                  // Add a new document in collection "cities"
                  Swal.fire("Updated!", "User Updated.", "success");
                  if(window.history.length > 2) {
                    this.$router.go(-1);
                  } else {
                    this.$router.push({path: "/"})
                  }
                })
                .catch((error) => {
                  // console.log(error);
                  Swal.fire("Failed!", error, "error");
                }); 
            })
            .catch((error) => {
              // console.log(error);
              Swal.fire("Failed!", error, "error");
            });
        } else {
          getFirebaseDataBackend()
          .updateData("users", this.id, userData)
            .then(() => {
              // Add a new document in collection "cities"
              Swal.fire("Updated!", "User Updated.", "success");
              if(window.history.length > 2) {
                this.$router.go(-1);
              }
            })
            .catch((error) => {
              // console.log(error);
              Swal.fire("Failed!", error, "error");
            }); 
        }
      }
    },
    async getUserDetails() {
      try {
        if(this.id){
          const data = await getFirebaseDataBackend().getDocData("users", this.id);
          if(data) {
              this.model = data
              this.model.dob *= 1000;
          }
        }
      } catch(error) {
        // console.log(error.message)
      }
    }
  },
  created() {
    initFirebaseDataBackend();
  },
  mounted() {
    this.initialModelState();
    if(this.$route.name.includes("new")){
      this.title = "Add User Details";
      this.schema = createUserFormSchema;
    } else {
      if(this.id) {
        this.title = "Edit User Details";
        this.getUserDetails();
        this.schema = editUserFormSchema;
      }
    }
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">User Detail</h4>
            <div class="card ">
              <form action="" @submit.prevent="submitUserDetails">
                  <vue-form-generator tag="div" :schema="schema" :options="formOptions" :model="model" />
                  <div class="d-flex justify-content-end mt-3 pr-4">
                      <button type="submit" class="btn btn-primary btn-lg">
                          {{ isSaving ? 'Saving...' : 'Submit'}}
                      </button>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>