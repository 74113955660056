<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Listing from "@/components/listing";
import appConfig from "@/app.config";
import { initFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import { getFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  page: {
    title: `Courses List -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Listing,
  },
  data() {
    return {
      title: "Courses List",
      items: [
        {
          text: "Courses",
        },
        {
          text: "List",
          active: true,
        },
      ],
      fields: [
        {
          key: "action",
          tdClass: "c-action",
        },
        {
          key: "code",
          label: "Course Code",
          tdClass: "c-sm",
        },
        {
          key: "name",
          label: "Course Name",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "short_desc",
          label: "Short Description",
          sortable: true,
          tdClass: "c-lg",
        },
        {
          key: "price",
          label: "Price (RM)",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
          tdClass: "c-sm c-currency",
          thClass: "c-currency",
        },
        {
          key: "suitable_for",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "type",
          sortable: true,
        },
        {
          key: "category",
          sortable: true,
        },
        {
          key: "suitable_for",
          sortable: true,
        },
        {
          key: "sales_start_date",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "sales_end_date",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "start_date",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "end_date",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "total_seats",
          sortable: true,
          tdClass: "c-sm",
        },
        {
          key: "seats_sold",
          sortable: true,
          tdClass: "c-sm",
        },
      ],
      listData: [],
      exportFieldName: [],
      filterFields: [
        "code",
        "name",
        "short_desc",
        "price",
        "suitable_for",
        "type",
        "category",
        "sales_start_date",
        "sales_end_date",
      ],
    };
  },
  methods: {
    async getCoursesList() {
      try {
        const queryParam = [
          {
            param_type: "where",
            field_name: "status",
            operator: "!=",
            value: "",
          },
        ];
        const data = await getFirebaseDataBackend().getListData(
          "courses",
          queryParam
        );
        if (data) {
          data.map((row) => {
            row.sales_start_date = moment
              .unix(row.sales_start_date)
              .format("MM/DD/YYYY");
            row.sales_end_date =
              row.sales_end_date > 0
                ? moment.unix(row.sales_end_date).format("MM/DD/YYYY")
                : "-";
            row.start_date = moment.unix(row.start_date).format("MM/DD/YYYY");
            row.end_date =
              row.end_date > 0
                ? moment.unix(row.end_date).format("MM/DD/YYYY")
                : "-";
          });
          this.listData = data;
        }
      } catch (error) {
        // console.log(error.message)
        Swal.fire("Error!", "Unable to retrieve Data", "error");
      }
    },
    generateExportFields() {
      this.exportFieldName = ["id"];
      for (const row of this.fields) {
        if (row.key !== "action") {
          this.exportFieldName.push(row.key);
        }
      }
      // Additional Fields
      this.exportFieldName.push("bundle_course_list");
      this.exportFieldName.push("long_desc");
      this.exportFieldName.push("learning_outcome");
    },
    addCourses() {
      this.$router.push({
        name: "course-detail",
        params: { pageTitle: "Add New Course" },
      });
    },
  },
  mounted() {
    initFirebaseDataBackend();
    this.getCoursesList();
    this.generateExportFields();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-inline-flex">
      <div>
        <b-button variant="primary" @click="addCourses" class="px-4"
          >Add</b-button
        >
      </div>

      <div class="px-3">
        <download-csv
          :data="listData"
          :fields="exportFieldName"
          :name="'subscription_list.csv'"
        >
          <b-button variant="primary">Download CSV</b-button>
        </download-csv>
      </div>
    </div>
    <div class="mt-3">
      <Listing
        :fields="fields"
        :listData="listData"
        :filterFields="filterFields"
        :editPageTitle="'Edit Course Details'"
        :editRoute="'course-detail-edit'"
        :sortBy="'id'"
      />
    </div>
  </Layout>
</template>