export const menuItems = [
    {
        id: 1,
        label: "menuitems.home.text",
        isTitle: true
    },
    {
        id: 1001,
        label: "menuitems.dashboard.text",
        icon: "uil-home-alt",
        badge: {
            variant: "primary"            
        },
        link: "/"
    },
    {
        id: 2,
        label: "menuitems.store.text",
        isTitle: true
    },
    {
        id: 2001,
        label: "menuitems.subscription.text",
        icon: "uil-repeat",
        link: "/subscriptions/list",
        subItems: [
            {
                id: 2001001,
                label: "menuitems.subscription.list.new",
                link: "/subscriptions/new",
                parentId: 2001
            },
            {
                id: 2001002,
                label: "menuitems.subscription.list.list",
                link: "/subscriptions/list",
                parentId: 2001
            },
        ]
    },
    {
        id: 2002,
        label: "menuitems.course.text",
        icon: "fas fa-book-open",
        link: "/courses/list",
        subItems: [
            {
                id: 2002001,
                label: "menuitems.course.list.new",
                link: "/courses/new/course-detail",
                parentId: 2002
            },
            {
                id: 2002002,
                label: "menuitems.course.list.list",
                link: "/courses/list",
                parentId: 2002
            },
        ]
    },
    {
        id: 2003,
        label: "menuitems.order.text",
        icon: "fas fa-file-invoice-dollar",
        link: "/order/list",
        subItems: [
            {
                id: 2003002,
                label: "menuitems.order.list.list",
                link: "/order/list",
                parentId: 2003
            },
        ]
    },
    {
        id: 2004,
        label: "menuitems.payment.text",
        icon: "fa fa-credit-card",
        link: "/payment/list",
        subItems: [
            {
                id: 2004002,
                label: "menuitems.payment.list.list",
                link: "/payment/list",
                parentId: 2004
            },
        ]
    },
    {
        id: 2005,
        label: "menuitems.user.text",
        icon: "fas fa-user",
        link: "/user/list",
        subItems: [
            {
                id: 2005001,
                label: "menuitems.user.list.new",
                link: "/user/new",
                parentId: 2005
            },
            {
                id: 2005002,
                label: "menuitems.user.list.list",
                link: "/user/list",
                parentId: 2005
            },
            {
                id: 2005003,
                label: "menuitems.userSubscription.list.list",
                link: "/user-subscription/list",
                parentId: 2005
            },
            {
                id: 2005004,
                label: "menuitems.userCourse.list.list",
                link: "/user-course/list",
                parentId: 2005
            },
        ]
    },
    {
        id: 2006,
        label: "menuitems.broadcast.text",
        icon: "fas fa-envelope-open-text",
        link: "/broadcast/list",
        subItems: [
            {
                id: 2006001,
                label: "menuitems.broadcast.list.new",
                link: "/broadcast/new",
                parentId: 2006
            },
            {
                id: 2006003,
                label: "menuitems.broadcast.list.emailList",
                link: "/broadcast/list/email",
                parentId: 2006
            },
            {
                id: 2006002,
                label: "menuitems.broadcast.list.smsList",
                link: "/broadcast/list/sms",
                parentId: 2006
            }            
        ]
    },
    // {
    //     id: 2006,
    //     label: "menuitems.userSubscription.text",
    //     icon: "fa fa-list-alt",
    //     link: "/user-subscription/list",
    //     subItems: [
    //         {
    //             id: 2006001,
    //             label: "menuitems.userSubscription.list.new",
    //             link: "/user-subscription/new",
    //             parentId: 2005
    //         },
    //         {
    //             id: 2006002,
    //             label: "menuitems.userSubscription.list.list",
    //             link: "/user-subscription/list",
    //             parentId: 2005
    //         },
    //     ]
    // },
    // {
    //     id: 2007,
    //     label: "menuitems.userCourse.text",
    //     icon: "fa fa-bookmark",
    //     link: "/user-course/list",
    //     subItems: [
    //         {
    //             id: 2007001,
    //             label: "menuitems.userCourse.list.new",
    //             link: "/user-course/new",
    //             parentId: 2005
    //         },
    //         {
    //             id: 2007002,
    //             label: "menuitems.userCourse.list.list",
    //             link: "/user-course/list",
    //             parentId: 2005
    //         },
    //     ]
    // },
    {
        id: 3,
        label: "menuitems.system.text",
        isTitle: true
    },
    {
        id: 3001,
        label: "menuitems.configuration.text",
        icon: "fas fa-cog",
        subItems: [
            {
                id: 3001001,
                label: "menuitems.configuration.list.website",
                link: "#",
                parentId: 3001
            },
            {
                id: 3001002,
                label: "menuitems.configuration.list.config",
                link: "#",
                parentId: 3001
            },          
            {
                id: 3001005,
                label: "menuitems.configuration.list.UDC",
                link: "#",
                parentId: 3001
            },            
            
        ]
    },
    
];

