import Vue from 'vue'
import BootstrapVue  from 'bootstrap-vue'
import router from './router'

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'

//this 2 is use for table sort icon
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'
// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false

import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'
 
Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});

import { initFirebaseBackend } from './helpers/firebase/authUtils';

import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
  configureFakeBackend();
}

import '@/assets/scss/app.scss'
 
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);

Vue.use(require("vue-chartist"));
Vue.use(VueMask);

import VueFormGenerator from 'vue-form-generator';
import 'vue-form-generator/dist/vfg.css';
import TelInputField from "./components/vue-tel-input-field";
import VueTelInput from "vue-tel-input";
import 'vue-tel-input/dist/vue-tel-input.css';
import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

Vue.use(VueTelInput, {
  dropdownOptions: {
    showDialCodeInSelection: true
  },
  validCharactersOnly: true,
  preferredCountries:["MY","SG"],
  mode: "international",
  autoFormat: true,
  // onlyCountries: ['MY']
});
Vue.component('field-tel-input', TelInputField);

Vue.use(VueFormGenerator, {
  validators: {
    strongPassword: (value) => {
      let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{5,}$/
      if(!value || value ===''){
        return []
      }
      
      if (!regex.test(value)) {
        return [ 'Password should be a minimum five characters, at least one uppercase letter, ' +
        'one lowercase letter and one number']
      } else {
        return []
      }
    }
  }
})

import JsonCSV from 'vue-json-csv';
Vue.component('downloadCsv', JsonCSV);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
