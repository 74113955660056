<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Listing from "@/components/listing";
import appConfig from "@/app.config";
import { initFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import { getFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  page: {
    title: `Broadcast Email List -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Listing,
  },
  data() {
    return {
      title: "Broadcast Email List",
      items: [
        {
          text: "Broadcast Email",
        },
        {
          text: "List",
          active: true,
        },
      ],
      fields: [
        {
          key: "batch_id",
          sortable: true,
          tdClass: "c-lg",
        },
        {
          key: "processed_date",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "to",
          label: "recipient email",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "processed_date",
          label: "Processed Date",
          sortable: true,
        },
        {
          key: "delivery.attempts",
          label: "attempts",
          sortable: true,
        },
        {
          key: "mail_processed_date",
          label: "Mail Processed Date",
          sortable: true,
          tdClass: "c-md",
        },
        {
          key: "template.name",
          label: "email template",
          sortable: true,
        },
        {
          key: "source",
          sortable: true,
        },
        {
          key: "delivery.state",
          label: "Sent To MailGun",
          sortable: true,
          tdClass: "c-md",
        },
      ],
      listData: [],
      exportFieldName: [],
      sortDesc: true,
    };
  },
  methods: {
    async getBroadcastList() {
      try {
        const queryParam = [
          {
            param_type: "where",
            field_name: "batch_id",
            operator: "!=",
            value: "",
          },
          {
            param_type: "orderBy",
            field_name: "batch_id",
            operator: "asc",
          },
          {
            param_type: "limit",
            value: 1000,
          },
        ];
        const resultData = await getFirebaseDataBackend().getListData(
          "mail",
          queryParam
        );
        console.log(resultData)
        if (resultData) {
          resultData.map((data) => {
            data.original_processed_date = data.processed_date ?? 0;
            data.processed_date = moment
                .unix(data.processed_date)
                .format("DD/MM/YYYY HH:mm:SS") ?? moment()
                .unix()
                .format("DD/MM/YYYY HH:mm:SS");
            data.sentToMailGun = data.delivery.state ?? "";
            data.templateName = data.template.name ?? "";
            data.attempts = data.delivery.attempts ?? "";
            data.mailGunMessageId = data.delivery.info.messageId ?? "";
            data.mail_processed_date = data.delivery.endTime
              ? moment(data.delivery.endTime.toMillis()).format(
                  "DD/MM/YYYY HH:mm:SS"
                )
              : "";
          });
          this.listData = resultData;
        }
      } catch (error) {
        console.log(error.message);
        Swal.fire("Error!", "Unable to retrieve Data", "error");
      }
    },
    generateExportFields() {
      this.exportFieldName.push("id");
      for (const row of this.fields) {
        if (row.key !== "action") {
          this.exportFieldName.push(row.key);
        }
      }

      // add additional fields
      this.exportFieldName.push("templateName");
      this.exportFieldName.push("sentToMailGun");
      this.exportFieldName.push("attempts");
      this.exportFieldName.push("mailGunMessageId");
    },
  },
  mounted() {
    initFirebaseDataBackend();
    this.getBroadcastList();
    this.generateExportFields();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-inline-flex">
      <div>
        <download-csv
          :data="listData"
          :fields="exportFieldName"
          :name="'broadcast_email_list.csv'"
        >
          <b-button variant="primary"
            >Download CSV</b-button
          >
        </download-csv>
      </div>
    </div>
    <div class="mt-3">
      <Listing
        :fields="fields"
        :listData="listData"
        :sortBy="'original_processed_date'"
        :sortDesc="sortDesc"
      />
    </div>
  </Layout>
</template>
