// import VueFormGenerator from 'vue-form-generator';
import moment from 'moment';

export default {
  groups: [
    {
      legend: 'Login Details',
      fields: [
        {
          type: 'input',
          inputType: 'email',
          label: 'Email Address',
          model: 'email',
          required: true,
          readonly: true,
          validator: ['email', 'required']
        },
        {
          type: 'input',
          inputType: 'password',
          label: 'Password',
          model: 'password',
          required: false,
          validator: ['strongPassword']
        }
      ]
    },
    {
      legend: 'Personal Info',
      fields: [      
        {
          type: 'input',
          inputType: 'text',
          label: 'First Name',
          model: 'first_name',
          required: true,
          validator: ['string', 'required']
        },
        {
          type: 'input',
          inputType: 'text',
          label: 'Last Name',
          model: 'last_name',
          required: true,
          validator: ['string', 'required']
        },
        {
          type: "tel-input",
          label: "Mobile No",
          model: "mobile_no"
        },
        {
          type: 'select',
          label: 'Gender',
          model: 'gender',
          values: [
            {id: 'male', name: 'Male'},
            {id: 'female', name: 'Female'}
          ],
          selectOptions: {
            allowEmpty:false,
            noneSelectedText: 'Choose One'
          },
          required: true,
          validator: ['string', 'required']
        },
        {
          type: "input",
          inputType: "date",
          label: "Date Of Birth",
          placeholder: "User's birth of date",
          model: "dob",
          required: true,
          min: moment("1900-01-01").toDate(),
          max: moment("2016-01-01").toDate(),
          validator: ['required','date'],
          set: function(model, val) {
              model.dob = new Date(val).valueOf();
          }
        },
        {
          type: "switch",
           label: "Subscribe Newsletter",
           model: "subscribe_news",
           multi: true,
           readonly: false,
           featured: false,
           disabled: false,
           default: true,
          textOn: "Subscribe",
          textOff: "Unsubscribe"
        },
        {
          type: 'select',
          label: 'User Status',
          model: 'status',
          values: [
            {id: 'active', name: 'Active'},
            {id: 'disabled', name: 'Inactive'},
            {id: 'deleted', name: 'Deleted'}
          ],
          selectOptions: {
            allowEmpty:false,
            noneSelectedText: 'Choose One'
          },
          required: true,
          validator: ['string', 'required'],
        },
      ]
    },
  ]
}