// import VueFormGenerator from 'vue-form-generator';
import moment from 'moment';
// import { initFirebaseDataBackend } from '../helpers/firebase/dataUtils';
// import { getFirebaseDataBackend } from '../helpers/firebase/dataUtils';

export const getSchema = (course_list, learningOutcomeCount, courseDetailCount) => {
  return {
    groups: [
      {
        legend: 'Basic Information',
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Course Code',
            model: 'code',
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Course name',
            model: 'name',
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Short Description',
            model: 'short_desc',
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'PaymentGateway Item Description',
            model: 'payment_desc',
            required: true,
            validator: ['required']
          },
          {
            type: 'textArea',
            rows: 5,
            label: 'Long Description',
            model: 'long_desc',
            required: false,
          },
          {
            type: "image",
            label: "course image",
            model: "image",
            required: true,
            hideInput: true,
            preview: true
          },
          {
            type: 'textArea',
            rows: courseDetailCount,
            label: 'course detail',
            model: 'course_detail',
            required: false,
          },
          {
            type: 'textArea',
            rows:learningOutcomeCount,
            label: 'Learning Outcome',
            model: 'learning_outcome',
            required: false,
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Price',
            model: 'price',
            min: 1,
            step: "0.01",
            required: true,
            validator: ['required']
          },
          {
            type: "switch",
            label: "12 Month Subscription Discount",
            model: "enable_discounted_price",
            multi: true,
            readonly: false,
            featured: false,
            disabled: false,
            default: true,
            textOn: "Enable",
            textOff: "Disable",
            onChanged (model, newVal) {
              if (!newVal) {
                model.discounted_price = 0;
              }
              return model;
            }
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Discounted Price',
            model: 'discounted_price',
            min: 0,
            step: "0.01",
            required: false,
            visible(model) {
              return model && model.enable_discounted_price;
            },
          },
          {
            type: 'select',
            label: 'Suitable For',
            model: 'suitable_for',
            default: "everyone",
            values: function () {
              return [
                { id:"beginner", name: "beginner" },
                { id:"intermediate", name: "intermediate" },
                { id:"advance", name: "advance" },
                { id:"everyone", name: "everyone" }
              ];
            },
            required: true,
            validator: ['required']
          },
          {
            type: 'select',
            label: 'Type',
            model: 'type',
            default: "general",
            values: function () {
              return [
                { id:"campaign", name: "campaign" },
                { id:"general", name: "general" },
                { id:"bundle", name: "bundle" }
              ];
            },
            required: true,
            validator: ['required'],
          },
          {
            type: "vueMultiSelect",
            label: "Bundle Courses List",
            placeholder: "Select courses",
            model: "bundle_course_list",
            required: true,
            validator: ['required'],
            values: () => {
              return course_list;
            },
            selectOptions: {
              multiple: true,
              clearOnSelect: false,
              closeOnSelect: false,
              hideSelected: true,
              searchable: true,
              showLabels: true,
              trackBy: "id",
              key: "id",
              label: "name",
            },
            // onChanged: function(model, newVal, oldVal) {
            //   console.log(newVal);
            //   console.log(oldVal);   
            // }, 
            visible(model) {
              return model && model.type === "bundle";
            }
          },
          {
            type: 'select',
            label: 'Category',
            model: 'category',
            default: "course",
            values: function () {
              return [
                { id:"course", name: "course" },
                { id:"online seminar", name: "online seminar" }
              ];
            },
            required: true,
            validator: ['required']
          },
          {
            type: "input",
            inputType: "date",
            label: "Sales Start Date",
            placeholder: "Sales Start Date",
            model: "sales_start_date",
            required: true,
            default: moment().add(1, "d").unix()*1000,
            max: moment().add(1, "y").unix()*1000,
            validator: ['required','date'],
            set: function(model, val) {
                model.sales_start_date = new Date(val).valueOf();
            }
          },
          {
            type: "input",
            inputType: "date",
            label: "Sales End Date (1/1/1970 = No End Date)",
            placeholder: "Sales End Date",
            model: "sales_end_date",
            min: 0,
            validator: ['date'],
            set: function(model, val) {
                model.sales_end_date = new Date(val).valueOf();
            }
          },
          {
            type: "input",
            inputType: "date",
            label: "Course Start Date",
            placeholder: "Course Start Date",
            model: "start_date",
            required: true,
            max: moment().add(1, "y").unix()*1000,
            validator: ['required','date'],
            set: function(model, val) {
                model.start_date = new Date(val).valueOf();
            }
          },
          {
            type: "input",
            inputType: "date",
            label: "Course End Date (1/1/1970 = No End Date)",
            placeholder: "Course End Date",
            model: "end_date",
            min: 0,
            validator: ['date'],
            set: function(model, val) {
                model.end_date = new Date(val).valueOf();
            }
          }
        ]
      },
      {
        legend: 'Additional Information',
        fields: [
          {
            type: 'input',
            inputType: 'number',
            label: 'Total Seats',
            model: 'total_seats',
            min: -1,
            required: true,
            validator: ['required']
          },
          {
            type: 'input',
            inputType: 'number',
            label: 'Seats Sold Since Inception',
            model: 'seats_sold_inception',
            min: 0,
          },
          {
            type: "input",
            inputType: "url",
            label: "Preview Video URL",
            placeholder: "Preview URL",
            model: "preview_video_url",
            validator: ['url']
          },
          {
            type: "input",
            inputType: "url",
            label: "Preview Document URL",
            placeholder: "Preview URL",
            model: "preview_document_url",
            validator: ['url']
          },
          {
            type: "switch",
            label: "Status",
            model: "status",
            multi: true,
            readonly: false,
            featured: false,
            disabled: false,
            default: true,
            textOn: "Active",
            textOff: "Inactive",
            valueOn: "active",
            valueOff: "inactive"
          },
          // {
          //   type: "vueMultiSelect",
          //   label: "Learning Outcome",
          //   placeholder: "Enter Learning Outcome",
          //   model: "learning_outcome",
          //   required: true,
          //   validator: ['required'],
          //   values: () => {
          //     return [{id:"default"}];
          //   },
          //   selectOptions: {
          //     multiple: true,
          //     clearOnSelect: false,
          //     closeOnSelect: false,
          //     hideSelected: true,
          //     searchable: true,
          //     showLabels: true,
          //     taggable: true,
          //     onNewTag: function(newTag, id, options){
          //       const tag = {
          //         id: newTag,
          //       }
          //       options.push(tag);
          //     },
          //     trackBy: "id",
          //     key: "id",
          //     label: "id",
          //   },
          //   // onChanged: function(model, newVal, oldVal) {
          //   // }, 
          //   // visible(model) {
          //   //   return model && model.type == "bundle";
          //   // }
          // },
        ]
      }
    ]
  }
};
