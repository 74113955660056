<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Listing from "@/components/listing";
import appConfig from "@/app.config";
import { initFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import { getFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import Swal from "sweetalert2";
import moment from "moment";
// moment.tz.setDefault("Asia/Kuala_Lumpur");

import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from "firebase/remote-config";

export default {
  page: {
    title: `Users List -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Listing,
  },
  data() {
    return {
      title: "Users List",
      items: [
        {
          text: "Users",
        },
        {
          text: "List",
          active: true,
        },
      ],
      fields: [
        {
          key: "email",
          sortable: true,
        },
        {
          key: "first_name",
          sortable: true,
        },
        {
          key: "last_name",
          sortable: true,
        },
        {
          key: "gender",
          sortable: true,
        },
        {
          key: "mobile_no",
          sortable: true,
        },
        {
          key: "course_count",
          label: "Course #",
          sortable: true,
        },
        {
          key: "subscription_count",
          label: "Subscription #",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: "subscription_expiry_date",
          label: "Subscription Expiry",
          sortable: true,
        },
        {
          key: "clicked_telegram",
          label: "Clicked Link",
          sortable: true,
        },
        {
          key: "registration_geo_location",
          label: "Registered Location",
          sortable: true,
        },
        {
          key: "referred_by_user_id",
          sortable: true,
        },        
        {
          key: "action",
        },
      ],
      listData: [],
      exportFieldName: [],
      subscriptionfilter: "",
      options: [
          { text: '', value: '' },
          { text: 'Subscription Expired', value: 'subscription_expired' },
          { text: 'Subscription Expiring', value: 'subscription_expiring' },
      ],
    };
  },
  methods: {
    async getUserList(filter) {
      try {
        let queryParam = [
        ];

        if (filter && filter !== "") {
          if (filter === 'subscription_expired') {
            queryParam.push({
              param_type: "where",
              field_name: "subscription_expiry_date",
              operator: "<",
              value: moment().unix(),
            })
          }
          else if (filter === 'subscription_expiring') {
            let daysToExpiry = 10;
            const remoteConfig = getRemoteConfig();
            // remoteConfig.settings.minimumFetchIntervalMillis = 36000;
            await fetchAndActivate(remoteConfig);
            const val = getValue(remoteConfig, 'days_to_expiry');
            if (val && val._value && val._value !== "") {
              daysToExpiry = val._value;
            }

            queryParam.push({
              param_type: "where",
              field_name: "subscription_expiry_date",
              operator: "<=",
              value: moment().add(daysToExpiry, "days").startOf("day").unix(),
            });
            queryParam.push({
              param_type: "where",
              field_name: "subscription_expiry_date",
              operator: ">",
              value: moment().unix(),
            });
          }
        }
        const resultData = await getFirebaseDataBackend().getListData(
          "users",
          queryParam
        );
        
        if (resultData) {
          let filteredUserData = resultData.filter(user => {
            return user.status !== 'deleted' && (!user.is_admin);
          });
          filteredUserData.map((data) => {
            if (data.subscription_expiry_date) {
              data.subscription_expiry_date = moment
                .unix(data.subscription_expiry_date)
                .format("DD/MM/YYYY");
            }
            if (data.dob) {
              data.dob = moment.unix(data.dob).format("DD/MM/YYYY");
            }
            if (data.last_login) {
              data.last_login = moment
                .unix(data.last_login)
                .format("DD/MM/YYYY");
            }
          });
          this.listData = filteredUserData;
        } else {
           this.listData = [];
        }
      } catch (error) {
        console.log(error.message);
        Swal.fire("Error!", "Unable to retrieve Data", "error");
      }
    },
    generateExportFields() {
      this.exportFieldName = ["id"];
      this.exportFieldName.push("referral_code");
      for (const row of this.fields) {
        if (row.key !== "action") {
          this.exportFieldName.push(row.key);
        }
      }
      this.exportFieldName.push("referred_by");
    },
    addUser() {
      this.$router.push({
        name: "user-new",
        params: { pageTitle: "Add New User" },
      });
    },
    onSubscriptionFilterChange () {
      this.getUserList(this.subscriptionfilter);
    }
  },
  mounted() {
    initFirebaseDataBackend();
    this.getUserList();
    this.generateExportFields();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-inline-flex">
      <div class="px-3">
          <label>Subscription Filter:&nbsp;&nbsp;&nbsp;</label>
          <b-form-select  v-model="subscriptionfilter" :options="options" @change="onSubscriptionFilterChange(subscriptionfilter)" cclass="mb-2">
          </b-form-select>
      </div>
      <b-button variant="primary" @click="addUser">Add</b-button>
      <div class="px-3">
        <download-csv
          :data="listData"
          :fields="exportFieldName"
          :name="'user_list.csv'"
        >
          <b-button variant="primary">Download CSV</b-button>
        </download-csv>
      </div>
    </div>
    <div class="mt-3">
      <Listing
        :fields="fields"
        :listData="listData"
        :editRoute="'user-detail'"
        :sortBy="'id'"
      />
    </div>
  </Layout>
</template>
