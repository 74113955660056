<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Listing from "@/components/listing";
import appConfig from "@/app.config";
import { initFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import { getFirebaseDataBackend } from "../../../helpers/firebase/dataUtils";
import Swal from "sweetalert2";

export default {
  page: {
    title: `Subscription List -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Listing,
  },
  data() {
    return {
      title: "Subscription List",
      items: [
        {
          text: "Subscription",
        },
        {
          text: "List",
          active: true,
        },
      ],
      fields: [
        {
          key: "action",
          tdClass: "c-action",
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "short_desc",
          sortable: true,
        },
        {
          key: "price",
          label: "Price (RM)",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
          tdClass: "c-sm c-currency",
          thClass: "c-currency",
        },
        {
          key: "period_month",
          sortable: true,
          tdClass: "c-action",
          thClass : "c-action"
        },
        {
          key: "featured_flag",
          sortable: true,
        },
        {
          key: "type",
          sortable: true,
        },
        {
          key: "saving_amount",          
          label: "Saving Amount (RM)",
          sortable: true,
          formatter: (value) => {
            return parseFloat(value).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
          tdClass: "c-sm c-currency",
          thClass: "c-currency",
        },
        {
          key: "status",
          sortable: true,
        },
      ],
      listData: [],
      exportFieldName: [],
    };
  },
  methods: {
    async getSubscriptionList() {
      try {
        const queryParam = [
          {
            param_type: "where",
            field_name: "name",
            operator: "!=",
            value: "",
          },
        ];
        const data = await getFirebaseDataBackend().getListData(
          "subscriptions",
          queryParam
        );
        if (data) {
          this.listData = data;
        }
      } catch (error) {
        // console.log(error.message)
        Swal.fire("Error!", "Unable to retrieve Data", "error");
      }
    },
    generateExportFields() {
      this.exportFieldName = ["id"];
      for (const row of this.fields) {
        if (row.key !== "action") {
          this.exportFieldName.push(row.key);
        }
      }
      this.exportFieldName.push("long_desc");
      this.exportFieldName.push("inclusive_list");
    },
    addSubscription() {
      this.$router.push({
        name: "subscriptions-new",
        params: { pageTitle: "Add New Subscription" },
      });
    },
  },
  mounted() {
    initFirebaseDataBackend();
    this.getSubscriptionList();
    this.generateExportFields();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-inline-flex">
      <div>
        <b-button variant="primary" @click="addSubscription" class="px-4"
          >Add</b-button
        >
      </div>
      <div class="px-3">
        <download-csv
          :data="listData"
          :fields="exportFieldName"
          :name="'subscription_list.csv'"
        >
          <b-button variant="primary">Download CSV</b-button>
        </download-csv>
      </div>
    </div>
    <div class="mt-3">
      <Listing
        :fields="fields"
        :listData="listData"
        :editRoute="'subscriptions-detail'"
        :sortBy="'id'"
      />
    </div>
  </Layout>
</template>