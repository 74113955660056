import { getFirebaseBackend } from '../../helpers/firebase/authUtils'
import axios from "axios";

export const state = {
    currentUser: sessionStorage.getItem('authUser'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
        if(process.env.VUE_APP_DEFAULT_AUTH === 'backend') {
            sessionStorage.setItem("authUser", newValue);
        }
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    currentUser(state) {
        return state.currentUser
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    // the first param is context, same as context.commit
    // eslint-disable-next-line no-unused-vars
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        if(process.env.VUE_APP_DEFAULT_AUTH === 'backend'){
            const user = {
                email: email,
                password: btoa(password),
            };
    
            return axios.post(`${process.env.VUE_APP_BASE_URL}/store/login`, user, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            })
                // eslint-disable-next-line no-unused-vars
                .then((response) => {
                    const token = response.data.data.token;
                    commit('SET_CURRENT_USER', token);
                    return response;
                })
                // eslint-disable-next-line no-unused-vars
                .catch((error) => {                
                    return error.response;
                });
        }

        return getFirebaseBackend().loginUser(email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // Logs out the current user.
    logOut({ commit, state }) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            if(process.env.VUE_APP_DEFAULT_AUTH === 'backend') {
                const data = null;
                const token = state.currentUser;
                return axios.post(`${process.env.VUE_APP_BASE_URL}/store/logout`, data, {
                    headers: {
                        authorization: token,
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    // eslint-disable-next-line no-unused-vars
                    .then((response) => {
                        if (response.status === 200) {
                            resolve(true);
                        }
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((error) => {
                        reject(handleError(error));
                    });
            }
            commit('SET_CURRENT_USER', null);
            
            // eslint-disable-next-line no-unused-vars
            getFirebaseBackend().logout().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(handleError(error));
            })
        });
    },

    // register the user
    register({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().registerUser(email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // register the user
    adminRegisterUser({getters}, { email, password } = {}) {
        if (getters.loggedIn) {
            return getFirebaseBackend().adminRegisterUser(email, password).then((response) => {
                const user = response
                return user
            });
        }
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if(process.env.VUE_APP_DEFAULT_AUTH === 'backend') {
            const userToken = state.currentUser;
            const data = null;

            return axios
                .post(`${process.env.VUE_APP_BASE_URL}/store/verify-token`, data, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        authorization: userToken,
                    },
                })
                .then((response) => {

                    if (response.status === 200) {
                        return response.data.status;
                    } else {
                        commit('SET_CURRENT_USER', null)
                    }
                })
                // eslint-disable-next-line no-unused-vars
                .catch((error) => {
                    commit('SET_CURRENT_USER', null)
                });
        } else {
            if (!state.currentUser) return Promise.resolve(null)
            const user = getFirebaseBackend().getAuthenticatedUser();
            commit('SET_CURRENT_USER', user)
            return user;
        }
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}

function handleError(error) {
    let errorMessage = (error.message) ? error.message : error;
    return errorMessage;
}