<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import * as subscriptionFormSchema from '../../../forms/subscriptionFormSchema';
import Swal from "sweetalert2";
import moment from 'moment';
import { initFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
import { getFirebaseDataBackend } from '../../../helpers/firebase/dataUtils';
// import { getAuth } from 'firebase/auth';

export default {
  page: {
    title: `Subscription Details -${appConfig.title}`,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  props: ["id","pageTitle"],
  data() {
    return {
      title: "Add New Subscription",
      model: null,
      inclusiveListCount: 4,
      schema: null,
      isSaving: false,
      formOptions: {
        validateAfterChanged: true,
        validateAfterLoad: false,
      },
      editorOption: {
        theme: 'snow'
      }
    };
  },
  methods: {
    submitSubscriptionDetails() {
      const subscriptionData = this.model;
      // console.log(subscriptionData)
      subscriptionData.fixed_expiry_date = subscriptionData.fixed_expiry_date / 1000;
      const subscriptionId = (this.id) ? this.id : null;
      subscriptionData.inclusive_list = subscriptionData.inclusive_list.split("\n");
     if(subscriptionId){
       getFirebaseDataBackend()
        .updateData("subscriptions", subscriptionId, subscriptionData)
        .then(() => {
          // Add a new document in collection "cities"
          Swal.fire("Updated!", "Subscription Updated.", "success");
          if(window.history.length > 2) {
            this.$router.go(-1);
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire("Failed!", "Subscription failed to update.", "error");
        });
     } else {
       getFirebaseDataBackend()
        .addData("subscriptions", subscriptionData)
        .then(() => {
          // Add a new document in collection "cities"
          Swal.fire("Created!", "Subscription Created.", "success");
          if(window.history.length > 2) {
            this.$router.go(-1);
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire("Failed!", "Subscription failed to create.", "error");
        }); 
     }         
    },
    async getSubscriptionDetails() {
      try {
        if(this.id){
          const data = await getFirebaseDataBackend().getDocData("subscriptions", this.id);
          if(data) {
            this.model = data;
            if(this.model.inclusive_list && this.model.inclusive_list.length > 0){
              this.inclusiveListCount = this.model.inclusive_list.length;
              this.model.inclusive_list = this.model.inclusive_list.join("\n");
            }
            this.model.fixed_expiry_date = this.model.fixed_expiry_date? this.model.fixed_expiry_date * 1000 : 0;
          }
        }
      } catch(error) {
        // console.log(error.message)
      }
    },
    initialModelState() {
      this.model = { 
            name: '',
            type: 'VIP1',
            price: 0,
            status: "active",
            short_desc: "",
            payment_desc: "",
            period_month: 0, 
            featured_flag: false,
            long_desc: '',
            inclusive_list: '',
            fixed_expiry_date: moment().add(1, "y").endOf("Year").unix(),
            enable_fixed_expiry: false,
      };
    },
  },
  async created() {
    initFirebaseDataBackend();
    this.initialModelState();
    if(this.$route.name.includes("new")){
      this.title = "Add Subscription Details";
    } else {
      if(this.id) {
        this.title = "Edit Subscription Details";
        await this.getSubscriptionDetails();
      }
    }
    this.schema = subscriptionFormSchema.getSchema(this.inclusiveListCount);
  },
  mounted() {
    if(this.$route.name.includes("new")){
      this.title = "Add Subscription Details";
      this.initialModelState();
    }
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Subscription Detail</h4>
            <div class="card ">
              <form action="" @submit.prevent="submitSubscriptionDetails">
                  <vue-form-generator tag="div" :schema="schema" :options="formOptions" :model="model" />
                  <div class="d-flex justify-content-end mt-3 pr-4">
                      <button type="submit" class="btn btn-primary btn-lg">
                          {{ isSaving ? 'Saving...' : 'Submit'}}
                      </button>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" module>
  .required label::after {
      content: "*";
  }
</style>