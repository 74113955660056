import { getFirebaseDataBackend } from "../../helpers/firebase/dataUtils";
import router from "../../router";
import sortByProperty from "../../helpers/sorting";
import Swal from "sweetalert2";

export const state = {
    courseID: '',
    courseMaterial: [],
    courseMaterialFromFB: [],
    removedCourseMaterial: [],
}

export const getters = {
    getCourseID: state => {
        return state.courseID;
    },
    getCourseMaterial: state => {
        return state.courseMaterial;
    },

    getSeqNo: state => {
        return state.seqNo;
    }
}

export const mutations = {

    SET_COURSE_ID: (state, courseID) => {
        state.courseID = courseID;
    },

    SET_COURSE_MATERIAL: (state, courseMaterial) => {
        state.courseMaterial = courseMaterial;
    },

    SET_COURSE_MATERIAL_FB: (state, courseMaterial) => {
        state.courseMaterialFromFB = courseMaterial;
    },

    UPDATE_COURSE_MATERIAL_LINE: (state, data) => {

        if (data.type === 'materialName')
            state.courseMaterial[data.index].materialName = data.value;
        if (data.type === 'materialType')
            state.courseMaterial[data.index].materialType = data.value;
        if (data.type === 'materialURL')
            state.courseMaterial[data.index].materialURL = data.value;
    },

    ADD_COURSE_MATERIAL_LINE: (state, newLineIndex) => {

        let newMaterialLine = {
            courseId: state.courseID,
            seqNo: newLineIndex,
            materialName: "",
            materialType: "Video",
            materialURL: "",
        }

        state.courseMaterial = state.courseMaterial.map(row => {
            if (row.seqNo >= newLineIndex) {
                row.seqNo++;
            }
            return row;
        });

        state.courseMaterial.push(newMaterialLine);
        state.courseMaterial.sort(sortByProperty(("seqNo")));
    },

    DELETE_COURSE_MATERIAL_LINE: (state, index) => {

        if (state.courseMaterial.length > 1) {
            state.courseMaterial = state.courseMaterial.map(row => {
                if (row.seqNo >= index) {
                    row.seqNo--;
                }
                return row;
            });
            // store removed line
            state.removedCourseMaterial.push(state.courseMaterial[index]);

            // removed line from state
            state.courseMaterial.splice(index, 1);
            state.courseMaterial.sort(sortByProperty(("seqNo")));

        }
    },

    RESET_COURSE_MATERIAL: (state) => {
        state.courseMaterial = [];
    },

    RESET_REMOVED_COURSE_MATERIAL: (state) => {
        state.removedCourseMaterial = [];
    },

    RESET_COURSE_ID: (state) => {
        state.courseID = "";
    }
}

export const actions = {

    loadCourseMaterial(context, payLoad) {

        const courseID = payLoad;

        const queryParam = [
            {
                param_type: "where",
                field_name: "course_id",
                operator: "==",
                value: courseID,
            },
        ];

        getFirebaseDataBackend().getListData(
            "media",
            queryParam
        ).then((data) => {

            if (data.length > 0) {
                const newData = data.sort(sortByProperty(("seq_no")));
                const courseMaterial = newData.map(row => {
                    let formatData = {
                        id: row.id,
                        courseId: row.course_id,
                        seqNo: row.seq_no,
                        materialName: row.material_name,
                        materialType: row.material_type,
                        materialURL: row.url,
                    }
                    return formatData;
                });

                context.commit('SET_COURSE_MATERIAL', courseMaterial);
                context.commit('SET_COURSE_MATERIAL_FB', courseMaterial);
            } else {
                context.commit('ADD_COURSE_MATERIAL_LINE', 0);
            }
        }).catch((error) => {
            console.log(error)
        })
    },

    saveCourseMaterial(context) {

        const removedMaterial = context.state.removedCourseMaterial;
        const rawData = context.state.courseMaterial;        
        const saveMaterial = rawData.map(row => {
            let formatData = {
                course_id: row.courseId,
                seq_no: row.seqNo,
                material_name: row.materialName,
                material_type: row.materialType,
                url: row.materialURL,
            }

            if (row.id) {
                formatData.id = row.id;
            }

            return formatData;
        });

        const validMaterial = saveMaterial.filter((row) => {
            if (row.material_name && row.url && row.material_type)
                return true;
        })

        const newMaterial = validMaterial.filter((row) => {
            return !row.id
        })

        const updateMaterial = validMaterial.filter((row) => {
            return row.id
        })

        // insert new material line
        if (newMaterial.length > 0) {

            newMaterial.forEach((row) => {
                getFirebaseDataBackend()
                    .addData("media", row)
                    .then(() => {
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })

        }

        // update existing material line
        if (updateMaterial.length > 0) {

            let newRowData = {}

            updateMaterial.forEach(row => {

                newRowData = Object.assign({}, row);
                delete newRowData["id"];

                getFirebaseDataBackend()
                    .updateData("media", row.id, newRowData)
                    .then(() => {                       
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        }
        // remove existing material line
        if (removedMaterial.length > 0) {
            removedMaterial.forEach(row => {
                getFirebaseDataBackend()
                    .deleteData("media", row.id)
                    .then(() => {                        
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        }

        Swal.fire("Done!", "Courses  material saved", "success");
        context.commit('RESET_COURSE_MATERIAL');
        context.commit('RESET_REMOVED_COURSE_MATERIAL');
        context.commit('RESET_COURSE_ID');

        if (updateMaterial.length > 0) {
            router.push({name:'courses-list'});
        } else {
            router.push({name:'course-detail'});
        }
    },
}
