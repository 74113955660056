var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._l((_vm.courseMaterial),function(material,index){return _c('div',{key:index,staticClass:"row px-3"},[_c('div',{staticClass:"col-4 py-1 px-1"},[_c('b-form-input',{class:{
                    'is-invalid':
                      _vm.isSubmit &&
                      _vm.$v.courseMaterial.$each[index].materialName.$error,
                  },attrs:{"value":material.materialName,"type":"text"},on:{"input":function($event){return _vm.updateCourseMaterial($event, 'materialName', index)}}}),(
                    _vm.isSubmit &&
                    _vm.$v.courseMaterial.$each[index].materialName.$error
                  )?_c('div',{staticClass:"invalid-feedback"},[(
                      !_vm.$v.courseMaterial.$each[index].materialName.required
                    )?_c('span',[_vm._v("This value is required.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-2 py-1 px-1"},[_c('multiselect',{attrs:{"value":material.materialType,"options":_vm.options,"selectLabel":""},on:{"input":function($event){return _vm.updateCourseMaterial($event, 'materialType', index)}}})],1),_c('div',{staticClass:"col-5 py-1 px-1"},[_c('b-form-input',{class:{
                    'is-invalid':
                      _vm.isSubmit &&
                      _vm.$v.courseMaterial.$each[index].materialURL.$error,
                  },attrs:{"value":material.materialURL,"type":"url","placeholder":"https://player.vimeo.com/video/12345678"},on:{"input":function($event){return _vm.updateCourseMaterial($event, 'materialURL', index)}}}),(
                    _vm.isSubmit &&
                    _vm.$v.courseMaterial.$each[index].materialURL.$error
                  )?_c('div',{staticClass:"invalid-feedback"},[(
                      !_vm.$v.courseMaterial.$each[index].materialURL.required
                    )?_c('span',[_vm._v("This value is required.")]):_vm._e(),(!_vm.$v.courseMaterial.$each[index].materialURL.url)?_c('span',[_vm._v("This value should be a valid url.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-1 d-flex justify-content-center align-items-center"},[_c('span',{staticClass:"text-success px-1 action",attrs:{"id":"add-line"},on:{"click":function($event){return _vm.addNewLine(index + 1)}}},[_c('i',{staticClass:"fas fa-plus"})]),_c('b-tooltip',{attrs:{"target":"add-line","delay":"500","variant":"secondary"}},[_vm._v("Add Line")]),_c('span',{staticClass:"text-danger px-2 action",attrs:{"id":"remove-line"},on:{"click":function($event){return _vm.removeLine(index)}}},[_c('i',{staticClass:"far fa-trash-alt"})]),_c('b-tooltip',{attrs:{"target":"remove-line","variant":"secondary","delay":"500"}},[_vm._v("Remove Line")])],1)])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-between"},[_c('b-button',{attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Back")]),_c('b-button',{attrs:{"type":"submit","variant":"danger"}},[_vm._v("Save")])],1)])],2)])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"col-4 py-1 px-1"},[_c('strong',[_vm._v(" Material Name ")])]),_c('div',{staticClass:"col-2 py-1 px-1"},[_c('strong',[_vm._v(" Material Type ")])]),_c('div',{staticClass:"col-6 py-1 px-1"},[_c('strong',[_vm._v(" URL ")])])])}]

export { render, staticRenderFns }